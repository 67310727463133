(function ($) {
	'use strict';

	class FormClass {
		constructor(formId) {
			var startTime;
			var timerInterval;
			var elapsedTime;

			//stripe
			var stripe = null;
			var elements = null;
			var cardElement = null;
			var achElement = null;
			var achPaymentIntent = null;
			var achPaymentMethod = null;
			var achInvoiceid = null;
			var achStripe_sub_id = null;
			var achsSartdate = null;
			var achEnddate = null;
			var debugMode = true;
			this.amountBeforeMontlyConversion = null;
			this.formId = formId;
			this.current = 0;
			this.donation_id = null;
			this.stepsValidatedTill = 0;
			this.accordion = null;
			this.validate = null;
			this.currency_id = null;
			this.stripeSrc = ajax_object.stripe_src;
			this.maxAmountMonthlyConversion = 200;
			this.defaultPresets = this.getUniqueFormElements('.default-presets');
			this.monthlyPresets = this.getUniqueFormElements('.monthly-presets');
			this.dailyPresets = this.getUniqueFormElements('.daily-presets');

			// Assign values to previously declared variables
			this.navigator = this.getUniqueFormElements('.navigator');
			this.successful = this.getUniqueFormElements('.successful');
			this.stepTitle = this.getUniqueFormElements('.step-title');
			this.stepOneTitle = this.getUniqueFormElements('#step-one-title');
			this.stepTwoTitle = this.getUniqueFormElements('#step-two-title');
			this.stepThreeTitle = this.getUniqueFormElements('#step-three-title');
			this.stepFourTitle = this.getUniqueFormElements('#step-four-title');
			this.radioElements = this.getUniqueFormElements("input[type='radio']");
			this.frequencyElements = this.getUniqueFormElements("input[name='frequency']");
			this.selectedFrequency = this.getUniqueFormElements("input[name='frequency']:checked");
			this.presetElements = this.getUniqueFormElements("input[name='presets']");
			this.amountField = this.getUniqueFormElements("input[name='amount']");
			this.monthlyAmountField = this.getUniqueFormElements("input[name='amount-monthly']");
			this.selectedFund = this.getUniqueFormElements("input[name='fund']:checked");
			this.selectedAmountLevel = this.getUniqueFormElements("input[name='fund']:checked");
			this.presetCustom = this.getUniqueFormElements('input[name="presets"][id="preset-custom"]');
			this.amountDiv = this.getUniqueFormElements('.amount');
			this.amountInputField = this.getUniqueFormElements('.amount input');
			this.stepForm = this.getUniqueFormElements('#stepForm');
			this.formContainer = this.getUniqueFormElements();
			this.form = this.getUniqueFormElements('#global-donation-form');
			this.backBtn = this.getUniqueFormElements('.back-btn');
			this.frequencyContainer = this.getUniqueFormElements('.frequency');
			this.loqateCheckbox = this.getUniqueFormElements('#loqate_option');
			this.loqateContainer = this.getUniqueFormElements('.loqate-container');
			this.searchInput = this.getUniqueFormElements('.searchInput');
			this.loqateSearchButton = this.getUniqueFormElements('#loqate-search-address');
			this.loqateAreaDropdown = this.getUniqueFormElements('#areaList');
			this.presetsAmountContainer = this.getUniqueFormElements('.presets'); //PK
			this.presetsQuantityContainer = this.getUniqueFormElements('.presets-quantity');
			this.getStatesBox = this.getUniqueFormElements('#state');
			this.getUKCode = this.getUniqueFormElements('#country_val');
			this.getUKCity = this.getUniqueFormElements("input[name='city']");
			this.getZipAddress = this.getUniqueFormElements("input[name='zip']");
			this.bankIdField = this.getUniqueFormElements("input[name='bank_id']");
			this.bankScreenshotField = this.getUniqueFormElements("input[name='filesrc']");
			this.addressTextarea = this.getUniqueFormElements('#addressTextarea');
			this.loqateSpinner = this.getUniqueFormElements('.loqate-search-icon');
			this.achOptionsBox = this.getUniqueFormElements('.ach-options-wrapper');
			this.achPaymentModeBox = this.getUniqueFormElements('.ach-payment-mode-selector');
			this.achManualOptionsBox = this.getUniqueFormElements('.ach-manual-bank-options');
			this.achAutomaticBtn = this.getUniqueFormElements('.automatic-verify-btn');
			this.achManualBtn = this.getUniqueFormElements('.manual-verify-btn');

			if (this.isGiftAidEnabled()) {
				this.giftAidElements = this.getUniqueFormElements("input[name='giftaid']");
				this.giftAidBtnsContainer = this.getUniqueFormElements('.giftaid-btns ul');
				this.giftAidWrapper = this.getUniqueFormElements('.gift-box-inner');
				this.selectedGiftAidOption = this.getUniqueFormElements("input[name='giftaid']:checked");
				this.giftAidAmountField = this.getUniqueFormElements("input[name='giftaid_amount']");
				this.giftAidTriggerField = this.getUniqueFormElements("input[name='is_reclaim_giftaid']");
				this.giftAidCountryField = this.getUniqueFormElements('select[name="bill_to_address_country_giftaid"]');
				this.giftAidStateField = this.getUniqueFormElements('select[name="bill_to_address_state_giftaid"]');
				this.giftAidBillingMethod = this.getUniqueFormElements('input[name="giftaid_billing_method"]');
				this.giftAidBillingMethodSelected = this.getUniqueFormElements('input[name="giftaid_billing_method"]:checked');
				this.toggleGiftAidBillingFields();
			}

			if (this.isDailyOnly()) {
				this.dailyStartDate = this.getUniqueFormElements("input[name='start_date']");
				this.dailyEndDate = this.getUniqueFormElements("input[name='end_date']");
			}

			this.amountContainer = this.getUniqueFormElements('.presets .amount');
			this.amountFixed = this.getUniqueFormElements(".presets .fixed input[name='amount']");
			this.fundContainer = this.getUniqueFormElements('.fund');
			this.paymentMethodContainer = this.getUniqueFormElements('.payment_methods');
			this.stepTracker = this.getUniqueFormElements('.step-tracker');
			this.stepperHead = this.getUniqueFormElements('.stepper');
			this.impactSection = this.getUniqueFormElements('.impact');
			this.impactLine = this.getUniqueFormElements('.impact-line');
			this.childIcon = this.getUniqueFormElements('.impact-icon .fa-child');
			this.impactIcon = this.getUniqueFormElements('.impact-icon .fa-circle-notch');
			this.buttton = this.getUniqueFormElements('.form-button');
			this.donateButtonwrapper = this.getUniqueFormElements('.donate-btn-section');
			this.butttonStepper = this.getUniqueFormElements('.form-stepper');
			this.countryField = this.getUniqueFormElements('select[name="country"]');
			this.selectedCountry = this.getUniqueFormElements('select[name="country"] option:selected');
			this.corporateMatchingCheckbox = this.getUniqueFormElements("input[name='corporate_match']");
			this.corporateMatchingTextBox = this.getUniqueFormElements("input[name='cp_organziation_name']");
			this.portfolioCheckbox = this.getUniqueFormElements("input[name='portfolio_check']");
			this.portfolioSelect = this.getUniqueFormElements('select[name="portfolio"]');
			this.projectCheckbox = this.getUniqueFormElements("input[name='project_check']");
			this.projectField = this.getUniqueFormElements('input[name="project_name"]');
			this.cityField = this.getUniqueFormElements('input[name="city"]');
			this.countryFieldLoqate = this.getUniqueFormElements('.loqate_feild_hide');
			this.resultFieldLoqate = this.getUniqueFormElements('.result_loqate');
			this.loqateAddressField = this.getUniqueFormElements('#result-address');
			this.loqate_feild_hide = this.getUniqueFormElements('loqate_feild_hide');
			this.stateField = this.getUniqueFormElements('select[name="state"]');
			this.step = this.getUniqueFormElements('.step');
			this.timeTaken = this.getUniqueFormElements('.total_time_taken');
			this.chapterIdField = this.getUniqueFormElements('input[name="country_id"]');
			this.customThankyouPage = this.getUniqueFormElements('input[name="thank_you_url"]');
			this.formName = this.getUniqueFormElements('input[name="form_name"]');
			this.chapterCountryCode = this.getUniqueFormElements('input[name="chapter_country_code"]');
			this.paymentMethod = this.getUniqueFormElements('input[name="payment_method"]');
			this.cardField = this.getUniqueFormElements('input[name="payment_method"][value="stripe"]');
			this.achField = this.getUniqueFormElements('input[name="payment_method"][value="bank-transfer"]');
			this.transactionFee = this.getUniqueFormElements('.transaction_fee');
			this.consumeFee = this.getUniqueFormElements('#consume_fee');
			this.consumeFeeText = this.getUniqueFormElements('#transaction_fee_text');
			this.bankDetails = this.getUniqueFormElements('#select_payment_method');
			this.transactionFeeAmount = this.getUniqueFormElements('#transaction_fee_amount');
			this.stripeContainer = this.getUniqueFormElements('li.stripe');
			this.achPMContainer = this.getUniqueFormElements('li.bank-transfer');
			this.cardContainer = this.getUniqueFormElements('#card-field');
			this.ChequeContainer = this.getUniqueFormElements('#chequeFeild');
			this.FileContainer = this.getUniqueFormElements('#screenShotFeild');
			this.HblContainer = this.getUniqueFormElements('.hbl');
			this.filelist = this.getUniqueFormElements('.slected-account-detail li'); // Get all li elements
			this.fileButton = this.getUniqueFormElements('#chooseFileBtn');
			this.uploadFile = this.getUniqueFormElements('#fileToUpload');
			this.filePreview = this.getUniqueFormElements('#filePreview');
			this.previewImage = this.getUniqueFormElements('#previewImage');
			this.accountList = this.getUniqueFormElements('#slected-account-detail');
			this.achContainer = this.getUniqueFormElements('#ach-field');
			this.gPayContainer = this.getUniqueFormElements('li.google-pay');
			this.GoogleContainer = this.getUniqueFormElements('#google-field');
			this.aPayContainer = this.getUniqueFormElements('li.apple-pay');
			this.chequeContainerSelect = this.getUniqueFormElements('li.cheque');
			this.bankTransferContainer = this.getUniqueFormElements('li.pk-bank-transfer');
			this.AppleContainer = this.getUniqueFormElements('#apple-field');
			this.achBankName = this.getUniqueFormElements('#ach-field .name');
			this.errorContainer = this.getUniqueFormElements('#error-message');
			this.achBankNumber = this.getUniqueFormElements('#ach-field .number');
			this.authenticationModal = this.getUniqueFormElements('.authentication-secure-modal');
			this.donateButtonText = this.getUniqueFormElements('#donate-button-text-amount');
			this.stepButton = this.getUniqueFormElements('.step-button-text-amount');
			this.doneTypingInterval = 500;
			this.currencyContainer = this.getUniqueFormElements('input[name="currency"]');
			this.monthlySwitch = this.getUniqueFormElements('input[name="monthly-switch"]');
			this.monthlyConversionText = this.getUniqueFormElements('.convert-monthly-digit-heading');
			this.monthlyConversionDescription = this.getUniqueFormElements('.convert-monthly-description');
			this.monthlyConversionAmountDigit = this.getUniqueFormElements('.convert-monthly-digit');
			this.monthlyConversionImpactDigit = this.getUniqueFormElements('.convert-monthly-impact-digit');
			this.monthlyConversionImpactString = this.getUniqueFormElements('.convert-monthly-impact-string');
			this.monthlyConversionBox = this.getUniqueFormElements('.monthly-converter-box');
			this.convertedToMonthly = this.getUniqueFormElements('input[name=monthly_converted]');
			this.productActive = this.getUniqueFormElements('.product-options li[product="active"]');
			this.productAmount = this.getUniqueFormElements('.presets-quantity .product-amount');
			this.productQuantityContainer = this.getUniqueFormElements('.presets-quantity');
			this.productQuantity = this.getUniqueFormElements('.presets-quantity input[name=product_quantity]');
			this.productLabel = this.getUniqueFormElements('.presets-quantity .product_label');
			this.formProductId = this.getUniqueFormElements('input[name=form_product_id');
			this.formproductQuantity = this.getUniqueFormElements('input[name=form_product_quantity');
			this.inputNumber = this.getUniqueFormElements('input[type=number]');

			this.init();
		}

		init() {
			console.log('form init');
			this.resetFormOnPageLoad();
			this.addStepstoStepTracker();
			this.addStepstoTitle();
			this.silentStepFormatting();
			this.setDebugMode();
			this.populateTitles();
			this.getCurrencyId();
			this.initMultiStepPart();
			this.goToStep(0);
			this.activateRadioButtons();
			this.addAmountFromPresets();
			this.setAmountFixed();
			this.showAmountNumberField();
			this.toggleGiftAidBtns();
			this.validateForm();
			this.addStepstoButtons();
			this.stepperNavigation();
			this.backNavigation();
			this.buttonNavigation();
			this.calculateImpact();
			this.formHelper();
			this.showCorporateMatchingOrganization();
			this.showPortfolioField();
			this.showProjectField();
			this.updateStates();
			this.formstartTimer();
			this.appendRequired();
			this.queueStripe();
			this.renderImpactonLoad();
			this.showTransactionFee();
			this.populateTransactionFee();
			// this.hideAchPM();
			this.hideAchMandate();
			this.checkloqateOption();
			this.searchForArea();
			this.hideloqateSpinner();
			this.hideCountryField();
			this.calculateGidAidAmount();
			this.pkFormConditions();
			this.currencyFormConditions();
			this.handle3DSecureResponse();
			this.updateDonateButtonText();
			this.convertToMonthly();
			this.onMonthlyConversionRender();
			this.translatePaymentMethodNames();
			this.populateDailyScheduleString();
			this.updateAmountonProductQuantityChange();
			this.updateProductDetailsonForm();
			this.limitInputNumber();
			this.achOptionActions();

			/*if (this.isMonthlyOnly()) {
				$(this.monthlyPresets).show();
				$(this.defaultPresets).hide();
				$(this.dailyPresets).hide();
			} else if ( this.isDailyOnly() ) {
				$(this.dailyPresets).show();
				$(this.defaultPresets).hide();
				$(this.monthlyPresets).hide();
			} else {
				$(this.defaultPresets).show();
				$(this.monthlyPresets).hide();
				$(this.dailyPresets).hide();
			}*/

			$('#one-time').click(() => {
				this.showDefaultPresets();
			});

			$('#monthly').click(() => {
				this.showMonthlyPresets();
			});

			this.productDropdown();
		}

		showDefaultPresets() {
			$(this.defaultPresets).show();
			$(this.monthlyPresets).hide();
		}

		showMonthlyPresets() {
			$(this.monthlyPresets).show();
			$(this.defaultPresets).hide();
		}

		resetFormOnPageLoad() {
			const self = this;
			$(window).on('load', function () {
				// Clear previous form data on page load
				$(self.form)[0].reset();
				self.clearAllPaymentMethods();
				self.clearErrorContainer();
			});
		}

		populateDailyScheduleString() {
			const self = this;

			if (self.isDailyOnly()) {
				let amount = self.getSelectedAmount();
				let formattedAmount = self.formatAmount(amount, self.getCurrencySymbol());

				let str =
					'<i class="fas fa-calendar-alt"></i> You will be charged <strong>' +
					formattedAmount +
					'</strong> daily, starting from <strong>' +
					self.getDailyRecurringStartDate().format('MMMM Do, YYYY') +
					'</strong> till <strong>' +
					self.getDailyRecurringEndDate().format('MMMM Do, YYYY') +
					'</strong>';

				if (amount > 0) $(this.form).find('.daily-schedule-string').html(str);
			}
		}

		formatAmount(amt, currencySign) {
			return currencySign + amt.toLocaleString('pk', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		}

		getStartDateDiffInDays() {
			const self = this;
			let now = moment().startOf('day');

			if (now > self.getDailyRecurringStartDate()) return self.getDailyRecurringEndDate().diff(now, 'days') + 1; // include today

			return self.getDailyRecurringEndDate().diff(self.getDailyRecurringStartDate(), 'days') + 1; // include today
		}

		getDailyRecurringStartDate() {
			let now = moment().startOf('day'),
				startDate = moment($(this.dailyStartDate).val(), 'YYYY/MM/DD');

			if (now > startDate) return now;

			return startDate;
		}

		getDailyRecurringEndDate() {
			return moment($(this.dailyEndDate).val(), 'YYYY/MM/DD');
		}

		handle3DSecureResponse() {
			let self = this;

			window.addEventListener('message', function (event) {
				if (event.data.event_id === '3DS-authentication-complete') {
					$('.authentication-secure-modal').dialog('close');
					let paymentIntent = event.data.data.paymentIntent;
					let paymentIntentClientSecret = event.data.data.paymentIntentClientSecret;
					// Check the PaymentIntent
					self.stripe.retrievePaymentIntent(paymentIntentClientSecret).then(function (result) {
						console.log(result);

						if (result.error) {
							// PaymentIntent client secret was invalid
						} else {
							if (result.paymentIntent.status === 'succeeded') {
								self.stripeIntentData = result.paymentIntent;
								$(self.form).find('input[name=client_secret]').val(result.paymentIntent.client_secret);
								//$(self.form).find('input[name=pm_id]').val(result.paymentIntent.payment_method)
								$(self.form).submit();
							}
						}
					});
				}
			});
		}

		addStepstoStepTracker() {
			var indexWithoutSilent = 0; // Counter for steps without 'step-silent' class
			const self = this;

			$(self.step).each(function (index) {
				var stepTracker = $(self.stepTracker).eq(index);

				// If step is silent skip it and navigate to next step
				if ($(this).hasClass('step-silent')) {
					indexWithoutSilent++;
				}
				stepTracker.attr('goToStep', indexWithoutSilent);
				indexWithoutSilent++;
			});
		}

		addStepstoTitle() {
			var indexWithoutSilent = 0; // Counter for steps without 'step-silent' class
			const self = this;

			$(self.step).each(function (index) {
				var stepTitle = $(self.stepTitle).eq(index);

				// If step is silent skip it and navigate to next step
				if ($(this).hasClass('step-silent')) {
					indexWithoutSilent++;
				}
				stepTitle.attr('stepTitle', indexWithoutSilent);
				indexWithoutSilent++;
			});
		}

		populateTitles(currentStep) {
			$(this.stepTitle).each(function (index) {
				const stepTitleAttr = $(this).attr('steptitle');
				if (parseInt(stepTitleAttr) === currentStep) {
					$(this).removeClass('hidden');
				} else {
					$(this).addClass('hidden');
				}
			});
		}

		changeCurrentStepTitle( title ) {

			let self = this;
			let currentStepElem = null

			if ( self.current == 1 )
				currentStepElem = self.stepOneTitle

			if ( self.current == 2 )
				currentStepElem = self.stepTwoTitle

			if ( self.current == 3 )
				currentStepElem = self.stepThreeTitle

			if ( self.current == 4 )
				currentStepElem = self.stepFourTitle

			$(currentStepElem).html(title);
		}

		silentStepFormatting() {
			const self = this;

			$(document).on('tcf-form-step-change', function (event, step) {
				var stepHTML = $(self.step).eq(step);

				if (stepHTML.hasClass('step-silent')) {
					self.hideNavigation();
				} else {
					self.showNavigation();
				}
			});
		}

		skipStepCheck(step) {
			const self = this;
			const stepHTML = $(self.step).eq(step);

			// for onetime to monthly conversion
			if (stepHTML.hasClass('step-onetime-only')) {
				if (self.getSelectedFrequency() === 'monthly' || (self.getSelectedFrequency() === 'one-time' && self.getSelectedAmount() > 200)) {
					return ++step;
				}
			}

			return step;
		}

		hideNavigation() {
			$(this.navigator).hide();
		}
		showNavigation() {
			$(this.navigator).show();
		}

		showloqateContainer() {
			const self = this;
			var loqateBoxField = $(self.loqateContainer);
			loqateBoxField.toggle();
		}

		hideloqateContainer() {
			const self = this;
			var loqateBoxField = $(self.loqateContainer);
			loqateBoxField.toggle();
		}

		hideCountryField() {
			const self = this;
			var checkLoqate = $(self.loqateCheckbox);

			if (checkLoqate && checkLoqate.length > 0) {
				var countryField = $(self.countryFieldLoqate);
				countryField.hide();
			}
		}

		showCountryField() {
			const self = this;
			var countryField = $(self.countryFieldLoqate);
			countryField.show();
		}

		hideResultField() {
			const self = this;
			var resultFieldLoqate = $(self.resultFieldLoqate);
			resultFieldLoqate.hide();
		}

		showResultField() {
			const self = this;
			var resultFieldLoqate = $(self.resultFieldLoqate);
			resultFieldLoqate.show();
		}

		removeAddressField() {
			const self = this;
			var loqateAddressField = $(self.loqateAddressField);
			loqateAddressField.removeClass('select');
			loqateAddressField.find('select').remove();
		}

		showloqateSpinner() {
			const self = this;
			var loqateSpinner = $(self.loqateSpinner);
			loqateSpinner.show();
		}
		hideloqateSpinner() {
			const self = this;
			var loqateSpinner = $(self.loqateSpinner);
			loqateSpinner.hide();
		}

		checkloqateOption() {
			const self = this;

			// Cache your jQuery objects to avoid re-selecting from DOM
			var $loqateCheckbox = $(self.loqateCheckbox),
				$statesBox = $(self.getStatesBox),
				$ukCode = $(self.getUKCode),
				$zipAddress = $(self.getZipAddress),
				$ukCity = $(self.getUKCity),
				$addressTextarea = $(self.addressTextarea);

			$loqateCheckbox.on('click', function (event) {
				if (this.checked) {
					// Checkbox is checked
					// Empty the fields if they are not already empty
					/* if ($statesBox.val() !== '') $statesBox.val('');
                    if ($ukCode.val() !== '') $ukCode.val('');
                    if ($zipAddress.val() !== '') $zipAddress.val('');
                    if ($ukCity.val() !== '') $ukCity.val('');
                    if ($addressTextarea.val() !== '') $addressTextarea.val('');*/

					// Now hide the loqate container, result field, and show the country field
					self.hideloqateContainer();
					self.hideResultField();
					self.showCountryField();
				} else {
					self.showloqateContainer();
					self.hideCountryField();
				}
			});
		}

		searchForArea() {
			const self = this;
			var searchBoxField = $(self.searchInput);
			var searchTimeout;

			$(self.loqateSearchButton).click(function () {
				$(searchBoxField).valid();
				var ukPostcodePattern = new RegExp(
					'^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$',
				);
				var searchText = $(searchBoxField).val();

				if ($(searchBoxField).valid()) {
					self.showResultField();
					self.showloqateSpinner();
					self.removeAddressField();
					$.ajax({
						type: 'POST',
						url: ajax_object.ajax_url,
						data: {
							action: 'loqate_handler',
							data: searchText,
						},
						success: function (response) {
							self.hideloqateSpinner();
							try {
								var jsonDataString = response;
								var jsonData = JSON.parse(jsonDataString);

								if (!jsonData || !jsonData.Items || !Array.isArray(jsonData.Items)) {
									throw new Error('Invalid JSON structure or missing Items array.');
								}

								var areaDropdown = self.populateLoqateArea(jsonData);

								self.searchContainerAddress(areaDropdown);
							} catch (error) {
								console.error('Error handling response:', error.message);
							}
						},
					});
				} else {
					self.hideResultField();
					self.hideloqateSpinner();
				}
			});
		}

		populateLoqateArea(areas) {
			var resultArea = document.getElementById('result-area');
			resultArea.innerHTML = '';
			var selectList = document.createElement('select');
			selectList.id = 'areaList';
			selectList.required = true;
			selectList.name = 'loqate-address-1';
			resultArea.classList.add('select');
			resultArea.classList.add('no-icon');

			var defaultOption = document.createElement('option');
			defaultOption.text = 'Select Address';
			defaultOption.value = '';
			defaultOption.selected = true;

			selectList.appendChild(defaultOption);

			areas.Items.forEach(function (item) {
				var option = document.createElement('option');
				option.value = item.Id;
				option.text = item.Text + ' ' + item.Description;
				option.setAttribute('type', item.Type);
				selectList.appendChild(option);
			});
			resultArea.appendChild(selectList);

			return resultArea;
		}

		searchContainerAddress(areaDropdown) {
			const self = this;

			areaDropdown.addEventListener('change', function () {
				var selectedOption = $(this).find('option:selected');
				var type = selectedOption.attr('type');

				if (type == 'Address') {
					var address = selectedOption.val();
					self.searchAddress(address);
					self.removeAddressField();
				} else {
					var container = selectedOption.val();
					self.hideCountryField();
					self.showloqateSpinner();
					$.ajax({
						type: 'POST',
						url: ajax_object.ajax_url,
						data: {
							action: 'loqate_handler',
							container: container,
						},
						success: function (response) {
							self.hideloqateSpinner();
							try {
								var jsonData = JSON.parse(response);

								if (!jsonData || !jsonData.Items || !Array.isArray(jsonData.Items)) {
									throw new Error('Invalid JSON structure or missing Items array.');
								}

								var addresses = self.populateLoqateAddresses(jsonData);

								self.selectAddress(addresses);
							} catch (error) {
								console.error('Error handling response:', error.message);
							}
						},
					});
				}
			});
		}

		populateLoqateAddresses(addresses) {
			var resultArea = document.getElementById('result-address');
			resultArea.innerHTML = '';
			var addressList = document.createElement('select');
			addressList.id = 'addressList';
			addressList.required = true;
			addressList.name = 'loqate-address-2';
			resultArea.classList.add('select');
			resultArea.classList.add('no-icon');
			resultArea.appendChild(addressList);
			var addressList = document.getElementById('addressList');

			// Clear existing options
			addressList.innerHTML = '';

			var defaultOption = document.createElement('option');
			defaultOption.text = 'Select Address';
			defaultOption.value = '';
			defaultOption.selected = true;

			addressList.appendChild(defaultOption);

			addresses.Items.forEach(function (item) {
				var option = document.createElement('option');
				option.value = item.Id;
				option.text = item.Text + ' ' + item.Description;
				option.setAttribute('class', item.Type);
				addressList.appendChild(option);
			});

			return resultArea;
		}

		selectAddress(addressDropdown) {
			const self = this;
			addressDropdown.addEventListener('change', function () {
				self.showloqateSpinner();
				var selectedOption = $(this).find('option:selected');
				var address = selectedOption.val();
				self.searchAddress(address);
			});
		}

		searchAddress(address) {
			const self = this;
			self.showloqateSpinner();
			self.hideCountryField();
			$.ajax({
				type: 'POST',
				url: ajax_object.ajax_url,
				data: {
					action: 'get_address_handler',
					id: address,
				},
				success: function (response) {
					self.hideloqateSpinner();
					var jsonDataString = response;
					self.showCountryField();
					var jsonData = JSON.parse(jsonDataString);
					jsonData.Items.forEach(function (item) {
						if (item.Language === 'ENG') {
							// Extracting the Id from each item and logging it
							self.populateAddress(item);
						}
					});
					// $("#state").val(jsonDataString.Items).change();
					// console.log(jsonDataString)
				},
			});
		}

		populateAddress(item) {
			$(this.getUKCode).val(53).change();
			$(this.getZipAddress).val(item.PostalCode);
			$(this.getUKCity).val(item.City);
			$(this.addressTextarea).val(item.Company + ' ' + item.Line1 + ' ' + item.Line2);
			$(this.getStatesBox).val(item.ProvinceName);
		}

		renderImpactonLoad() {
			const self = this;
			var selectedRadio = $(self.presetElements + ':first');
			var impactValue = selectedRadio.attr('impact');

			if (impactValue) {
				self.setImpactText(impactValue);
			} else {
				self.renderImpact();
			}
		}

		async renderImpact() {
			const amount = $.trim(this.getSelectedAmount());

			if (amount !== null && amount !== '') {
				let impact = await this.getImpact(amount, this.getSelectedDonationType());
				this.setImpactText(impact);
			}
		}

		setImpactText(impact) {
			const self = this;
			self.impactJump();
			const studentString = self.getStudentSring(impact);

			if (impact > 0) {
				if (self.getLocale() == 'it-IT') {
					if (impact > 1) {
						$(self.impactLine).html(`La tua donazione sosterrà l’istruzione di un <b>${impact} bambini</b> per un anno.`);
					} else {
						$(self.impactLine).html(`La tua donazione sosterrà l’istruzione di un bambino per un anno.`);
					}
				} else {
					$(self.impactLine).html(`Your donation will impact the life of <b>${impact} ${studentString}</b> per year.`);
				}
			} else {
				if (self.getLocale() == 'it-IT') {
					$(self.impactLine).html("La tua donazione sostiene il fondo per l'istruzione generale del TCF.");
				} else {
					$(self.impactLine).html("Your donation supports <b>TCF's general education fund.</b>");
				}
			}
		}

		formLoading($state) {
			const self = this;
			if ($state == true) {
				$(self.buttton).attr('disabled', true);
				$(self.backBtn).attr('disabled', true);
				$(self.stepTracker).attr('disabled', true);
				$(self.currencyContainer).attr('disabled', true);
				$(self.buttton + ' .loading-icon').show();
				$(self.buttton + ' .inner').hide();
				$(self.childIcon).hide();
				$(self.impactIcon).show();
			} else {
				$(self.buttton).attr('disabled', false);
				$(self.backBtn).attr('disabled', false);
				$(self.stepTracker).attr('disabled', false);
				$(self.currencyContainer).attr('disabled', false);
				$(self.buttton + ' .loading-icon').hide();
				$(self.buttton + ' .inner').show();
				$(self.childIcon).show();
				$(self.impactIcon).hide();
			}
		}

		impactJump() {
			const self = this;
			// add a class to the element
			$(self.childIcon).addClass('jump');
			// remove the class after 1 second (1000 milliseconds)
			setTimeout(function () {
				$(self.childIcon).removeClass('jump');
			}, 300);
		}

		calculateImpact() {
			const self = this;

			$(self.presetElements).click(function () {
				if ($(this).attr('id') !== 'preset-custom') {
					var impactAttr = $(this).attr('impact');
					var frequency = self.getSelectedDonationType();

					if (typeof impactAttr !== 'undefined' && impactAttr !== null && frequency !== 'monthly') {
						self.setImpactText($(this).attr('impact'));
					} else {
						self.renderImpact();
					}
				}
			});

			$(this.frequencyElements).click(function () {
				self.showSelectedProductPresets();
				self.renderImpact();
			});

			var typingTimer;

			// When the user types in the text box, start the timer

			if (self.isMonthlyOnly()) {
				$(self.monthlyAmountField).on('keyup', function () {
					clearTimeout(typingTimer);

					// update in primary amount field too
					self.setAmountValue($(this).val());

					typingTimer = setTimeout(function () {
						self.renderImpact();
					}, self.doneTypingInterval);
				});

				return;
			}

			if (self.isOnceOnly()) {
				$(self.amountField).on('keyup', function () {
					clearTimeout(typingTimer);

					typingTimer = setTimeout(function () {
						self.renderImpact();
					}, self.doneTypingInterval);
				});

				return;
			}

			$(self.monthlyAmountField).on('keyup', function () {
				clearTimeout(typingTimer);

				// update in primary amount field too
				self.setAmountValue($(this).val());

				typingTimer = setTimeout(function () {
					self.renderImpact();
				}, self.doneTypingInterval);
			});

			$(self.amountField).on('keyup', function () {
				clearTimeout(typingTimer);

				if (self.isDailyOnly()) {
					self.populateDailyScheduleString();
				}

				typingTimer = setTimeout(function () {
					self.renderImpact();
				}, self.doneTypingInterval);
			});

			$(self.productQuantity).on('keyup', function () {
				clearTimeout(typingTimer);

				typingTimer = setTimeout(function () {
					self.renderImpact();
				}, self.doneTypingInterval);
			});

			$(document).on('tcf-form-product-change', function (event, product) {
				clearTimeout(typingTimer);

				typingTimer = setTimeout(function () {
					self.renderImpact();
				}, self.doneTypingInterval);
			});

			return;
		}

		calculateGidAidAmount() {
			const self = this;

			$(document).on('tcf-form-step-change', function (event, step) {
				if (self.isGiftAidEnabled()) {
					let totalAmt = self.getSelectedAmount();
					let giftAidAmt = totalAmt * (25 / 100) + totalAmt;
					$(self.giftAidWrapper).find('.giftaid-before-amt').html(self.getFormattedAmount());
					$(self.giftAidWrapper).find('.giftaid-after-amt').html(self.formatAmount(giftAidAmt));
					$(self.giftAidAmountField).val(giftAidAmt);
				}
			});
		}

		pkFormConditions() {
			const self = this;

			$(document).on('tcf-form-step-change', function (event, step) {
				if (self.getSelectedFrequency() === 'monthly') {
					self.hideChequePM();
					self.hideBankTransferPM();
				} else {
					self.showChequePM();
					self.showBankTransferPM();
				}

				if (self.getSelectedCurrency() == 'AED') {
					self.hideBankTransferPM();
				}

				if (self.getSelectedAmount() < 5000) {
					self.hideChequePM();
				}
			});
		}

		currencyFormConditions() {
			const self = this;
			var currencyCheck = $(self.currencyContainer);
			currencyCheck.on('click', function () {
				if (!self.isIntEqual(self.getChapterId(), $(this).data('currencyid'))) {
					let currencyValue = $(this).val();
					let countryid = $(this).data('countryid');
					let currencyid = $(this).data('currencyid');
					let countryCode = $(this).data('countrycode');
					let countrySymbol = $(this).data('currencysymbol');

					var data = {
						currencyValue: currencyValue,
						countryid: countryid,
						currencyid: currencyid,
						countrysymbol: countrySymbol,
					};

					$.ajax({
						type: 'POST',
						url: ajax_object.ajax_url,
						data: {
							action: 'currency_handler',
							data: data,
						},
						beforeSend: function () {
							self.formLoading(true);
						},
						success: function (response) {
							let presets = $(self.formContainer).find('.section.presets');
							presets.before(response).detach();
							//$(response).insertAfter($(document).find(self.formContainer + ' .section.currency'));

							$(self.getUniqueFormElements('input[name="chapter_country_code"]')).val(countryCode);
							$(self.getUniqueFormElements('input[name="country_id"]')).val(countryid);
							$(self.getUniqueFormElements('input[name="currency_id"]')).val(currencyid);
							$(self.getUniqueFormElements('input[name="currency_symbol"]')).val(countrySymbol);

							// rebind events
							self.addAmountFromPresets();
							self.showAmountNumberField();
							self.calculateImpact();
							self.activateRadioButtons();
							self.updateDonateButtonText();
							self.showTransactionFee();

							if (self.getActiveProductType() == 'quantity') {
								self.hideAmountPresets();
							}

							if (self.getSelectedFrequency() == 'monthly') {
								self.showMonthlyPresets();
							}

							if (self.getSelectedFrequency() == 'one-time') {
								self.showDefaultPresets();
							}

							// Uncheck transaction fee if checked before
							$(self.consumeFee).prop('checked', false);
							self.resetTransactionFee();
							self.formLoading(false);
						},
						complete: function () {
							self.getProductRates(countryid);
						},
					});
				}
			});
		}

		achOptionActions() {
			const self = this;

			$(self.achAutomaticBtn).on('click', function (e) {
				e.preventDefault();
				self.appendACHOptionSelectionInput( $(this).data('ach-option') )

				// run toggle after option is selected
				self.toggleACHBtnLoader();

				self.mountPaymentMethodElements('ACH');
			})

			$(self.achManualBtn).on('click', function (e) {
				e.preventDefault();
				self.appendACHOptionSelectionInput( $(this).data('ach-option') )

				// run toggle after option is selected
				self.toggleACHBtnLoader();

				self.mountPaymentMethodElements('ACH');
				console.log('manual btn clicled');
			})
		}

		appendACHOptionSelectionInput( selected ) {
			const self = this;

			if ($(self.form).find('input[name="ach_option"]').length > 0) {
				$(self.form).find('input[name="ach_option"]').attr('value', selected);
			} else {
				$(self.form).append('<input type="hidden" name="ach_option" value="'+selected+'" class="hidden-input">');
			}
		}

		showACHOptionsBox() {
			let self = this;
			$(self.paymentMethodContainer).hide();
			$(self.achOptionsBox).show();
			$(self.achOptionsBox).addClass('active');
			self.hideDonateBtnWrapper();
		}

		hideACHOptionsBox() {
			let self = this;
			$(self.achOptionsBox).hide();
			$(self.achOptionsBox).removeClass('active');
			$(self.paymentMethodContainer).show();
			self.hideDonateBtnWrapper();
		}

		hideACHPaymentModeBox() {
			$(this.achPaymentModeBox).hide();
		}

		showACHPaymentModeBox() {
			$(this.achPaymentModeBox).show();
		}

		hideAchManualBtn() {
			$(this.achManualBtn).hide();
		}

		showAchManualBtn() {
			$(this.achManualBtn).show();
		}

		hideAchAutomaticBtn() {
			$(this.achAutomaticBtn).hide();
		}

		showAchAutomaticBtn() {
			$(this.achAutomaticBtn).show();
		}

		showStepperNav() {
			$(this.stepperHead).show();
		}

		hideStepperNav() {
			$(this.stepperHead).hide();
		}

		toggleACHBtnLoader() {
			let self = this;

			if ( self.getCurrentACHPaymentOption() == 'automatic' ) {
				$(this.achAutomaticBtn).find('.spinAchauto').toggle();
			}

			if ( self.getCurrentACHPaymentOption() == 'manual' ) {
				$(this.achManualBtn).find('.spinAchauto').toggle();
			}
		}

		toggleACHBankOptionsBox() {
			let self = this;
			$( self.achManualOptionsBox ).toggle();
		}

		getCurrentACHPaymentOption() {
			const self = this;
			return $(self.form).find('input[name="ach_option"]').attr('value');
		}

		getProductRates(countryId) {
			const self = this;
			if (self.isProductsEnabled()) {
				var products_data = {
					countryid: countryId,
					debug_mode: self.getDebugMode(),
				};

				$.ajax({
					type: 'POST',
					url: ajax_object.ajax_url,
					data: {
						action: 'products_handler',
						data: products_data,
					},
					beforeSend: function (xhr) {
						self.formLoading(true);
					},
					success: function (response) {
						const productsUpdated = self.updateProductRates(response);
						if (productsUpdated) {
							self.showSelectedProductPresets();
						}
						self.formLoading(false);
					},
				});
			}
		}

		getUniqueFormElements(selector) {
			if (selector) {
				return '#' + this.formId + ' ' + selector;
			} else {
				return '#' + this.formId;
			}
		}

		activateRadioButtons() {
			const self = this;

			// Add a click event listener to all radio buttons
			$(self.radioElements).click(function () {
				// Get the name of the clicked radio button
				var name = $(this).attr('name');

				// Select the parent element of the clicked radio button and toggle the "active" class on it
				$(this).parent().addClass('active');

				// Remove the "active" class from the parent elements of all other radio buttons with the same name
				$(self.radioElements + '[name="' + name + '"]')
					.not(this)
					.parent()
					.removeClass('active');

				self.logGroup('Active class added', name, $(this).attr('value'), 'activateRadioButtons');
			});
		}

		setAmount(amounts) {
			const self = this;

			const updateAmounts = function () {
				const frequencyValue = $(this).val(); // Use this.val() to get the updated value
				const amount = frequencyValue === 'one-time' ? amounts.onetime : amounts.monthly;
				self.scrolltoForm();
				self.goToStep(0);
				self.renderImpact();
				self.toggleAmountField(true);
				self.setPresetCustom();
				self.addAmountFromPresets(amount);

				self.log('frequencyElements Clicked');
			};

			$(this.frequencyElements).on('change', updateAmounts);

			updateAmounts.call($(this.frequencyElements)[0]);
		}

		setAmountValue(value) {
			const oldAmount = this.getSelectedAmount();
			const amount = document.querySelector(this.getSelectedFrequency() === 'monthly' ? this.monthlyAmountField : this.amountField);
			amount.value = value;
			const changeEvent = new Event('change');
			amount.dispatchEvent(changeEvent);
			this.updateAmountinProducts();
			this.logGroup('Amount value changed', oldAmount, this.getSelectedAmount(), 'setAmountValue');
		}

		setPresetCustom() {
			$(this.presetCustom).click();
			$(this.presetCustom).parent().addClass('active');
		}

		addAmountFromPresets(amount) {
			const self = this;

			const currentVal = typeof amount !== 'undefined' && !isNaN(amount) ? amount : $(self.presetsAmountContainer + ' .active input').val();

			self.setAmountValue(currentVal);

			// Add a click event listener to all radio buttons with the name "presets"
			$(self.presetElements).click(function () {
				// Get the value of the clicked radio button
				var value = $(this).val();

				// Set the value of the hidden input with the name "amount" to the clicked radio button's value
				self.setAmountValue(value);

				if (self.isDailyOnly()) {
					self.populateDailyScheduleString();
				}
			});
		}

		setAmountFixed() {
			const self = this;

			if (document.querySelector(self.amountFixed)) {
				var freq = $(self.frequencyElements).val();
				var amount_once = $(self.amountFixed).attr('amount_once');
				var amount_monthly = $(self.amountFixed).attr('amount_monthly');

				if ($(self.frequencyElements).val() == 'one-time') {
					self.addAmountFromPresets(amount_once);
				} else {
					self.addAmountFromPresets(amount_monthly);
				}

				$(self.frequencyElements).on('click', function () {
					if ($(this).val() == 'one-time') {
						self.addAmountFromPresets(amount_once);
					} else {
						self.addAmountFromPresets(amount_monthly);
					}
				});
			}
		}

		showAmountNumberField() {
			const self = this;

			// Add a click event listener to all radio buttons with the name "presets"
			$(self.presetElements).click(function () {
				// Check if the clicked radio button has the id "presets-custom"
				if ($(this).attr('id') === 'preset-custom') {
					// If the clicked radio button has the id "presets-custom", show the fieldset with the class "amount"
					self.toggleAmountField(true);
				} else {
					// If the clicked radio button does not have the id "presets-custom", hide the fieldset with the class "amount"
					self.toggleAmountField(false);
				}
			});
		}

		toggleGiftAidBtns() {
			const self = this;

			$(self.giftAidElements).click(function () {
				self.toggleAmountField(false);
				self.toggleGiftAidWrapper();
			});
		}

		toggleGiftAidWrapper() {
			const self = this;

			if (self.getGiftAidValue()) {
				$(this.giftAidWrapper).addClass('active');
				$(this.giftAidTriggerField).val('1');
			} else {
				$(this.giftAidWrapper).removeClass('active');
				$(this.giftAidTriggerField).val('0');
			}
		}

		toggleGiftAidBillingFields() {
			const self = this;

			$(self.giftAidBillingMethod).change(function () {
				var value = $(this).val();

				console.log(value);
				if (value == '1') $(this.form).find('.gift-box-address .donor-fields').hide();

				if (value == '2') $(this.form).find('.gift-box-address .donor-fields').show();
			});
		}

		toggleGidAidReadmore(e) {
			$(this.form).find('.giftaid-notice').toggle();

			if ($(e.target).html() === 'Read more ..') {
				$(e.target).html('Read less');
				return;
			}

			if ($(e.target).html() === 'Read less') {
				$(e.target).html('Read more ..');
				return;
			}
		}

		decodeSourceCookie() {
			return this.decodeCookie('donation_source=');
		}

		decodeUTMCookie() {
			return this.decodeCookie('donation_utm=');
		}

		decodeCookie(cookieName) {
			const cookie = document.cookie
				.split(';')
				.map((cookie) => cookie.trim())
				.find((cookie) => cookie.startsWith(cookieName));

			if (cookie) {
				const sourceCookieValue = cookie.split('=')[1];
				return JSON.parse(decodeURIComponent(sourceCookieValue));
			}

			return null;
		}

		collectSourceURL() {
			const sourceCookie = this.decodeSourceCookie();

			if (sourceCookie && sourceCookie.referrer) {
				const referrerURL = new URL(sourceCookie.referrer);

				return referrerURL.href;
			}

			return null;
		}

		collectSourceData() {
			const sourceURL = this.collectSourceURL();
			const sourceDate = this.collectSourceDate();

			if (this.isNotEmptyURL(sourceURL)) {
				let source = {};
				source.url = sourceURL;
				source.date_recorded = sourceDate;
				source.expires_in = this.getExpiresInArray(sourceDate);
				return source;
			}

			return null;
		}

		collectUTMData() {
			const trackingCookie = this.decodeUTMCookie();
			const utmDate = this.collectUTMDate();

			if (trackingCookie && trackingCookie.utm) {
				let utm = {};
				utm.data = trackingCookie.utm;
				utm.date_recorded = utmDate;
				utm.expires_in = this.getExpiresInArray(utmDate);
				return utm;
			}
			return null;
		}

		collectUTMSource() {
			const trackingCookie = this.decodeUTMCookie();

			if (trackingCookie && trackingCookie.utm.utm_source) {
				const utmSource = trackingCookie.utm.utm_source;
				return utmSource;
			}

			return null;
		}

		collectUTMMedium() {
			const trackingCookie = this.decodeUTMCookie();

			if (trackingCookie && trackingCookie.utm.utm_medium) {
				const utmMedium = trackingCookie.utm.utm_medium;
				return utmMedium;
			}

			return null;
		}

		collectUTMCampaign() {
			const trackingCookie = this.decodeUTMCookie();

			if (trackingCookie && trackingCookie.utm.utm_campaign) {
				const utmCampaign = trackingCookie.utm.utm_campaign;
				return utmCampaign;
			}

			return null;
		}

		collectUTMContent() {
			const trackingCookie = this.decodeUTMCookie();

			if (trackingCookie && trackingCookie.utm.utm_content) {
				const utmContent = trackingCookie.utm.utm_content;
				return utmContent;
			}

			return null;
		}

		collectUTMTerm() {
			const trackingCookie = this.decodeUTMCookie();

			if (trackingCookie && trackingCookie.utm.utm_term) {
				const utmTerm = trackingCookie.utm.utm_term;
				return utmTerm;
			}

			return null;
		}

		hasUTMData(cookie) {
			return cookie && cookie.utm && Object.values(cookie.utm).some((value) => this.isNotEmpty(value));
		}

		collectSourceDate() {
			const trackingCookie = this.decodeSourceCookie();
			const sourceURL = this.collectSourceURL();

			if (trackingCookie && trackingCookie.dateRecorded && trackingCookie.dateRecorded && this.isNotEmptyURL(sourceURL)) {
				return trackingCookie.dateRecorded;
			}
			return null; // Return current date if dateRecorded is unavailable
		}

		collectUTMDate() {
			const trackingCookie = this.decodeUTMCookie();

			if (this.hasUTMData(trackingCookie)) {
				return trackingCookie.dateRecorded;
			}
			return null; // Return current date if dateRecorded is unavailable
		}

		initMultiStepPart() {
			if ($.fn.accordion) {
				// jQuery UI Accordion is loaded and available

				// Define and initialize the accordion with the given options
				this.accordion = $(this.stepForm).accordion({
					// Set the height style of the accordion to 'content'
					heightStyle: 'content',
					// Make the accordion panels collapsible
					collapsible: true,
					// Set the animation options for the accordion
					animate: {
						// Set the easing function for the animation to 'linear'
						easing: 'linear',
						// Set the duration of the animation to 100 milliseconds
						duration: 100,
						// Disable queuing of the animation
						queue: false,
					},
					// Define the callback function that is called after each accordion panel is opened or closed
					step: function (now, fx) {
						// Set the left position of the current accordion panel to the current animation value (in percent)
						$(this).css('left', now + '%');
					},
				});
			} else {
				console.log('jQuery Accordion is not loaded');
			}
		}

		validateForm() {
			const self = this;

			self.validate = $(self.form).validate({
				errorClass: 'warning',
				onkeyup: false,
				onfocusout: false,
				focusInvalid: false,
				invalidHandler: function (form, validator) {
					if (!validator.numberOfInvalids()) return;

					var elementOffsetTop = $(validator.errorList[0].element).offset().top - 100;
					var windowHeight = $(window).height();
					var scrollTop = $(window).scrollTop();

					if (elementOffsetTop < scrollTop || elementOffsetTop > scrollTop + windowHeight) {
						// Element is not fully visible in the viewport, so scroll to it
						$('html, body').animate(
							{
								scrollTop: elementOffsetTop,
							},
							300,
						);
					}
				},
				rules: {
					firstname: true,
					lastname: true,
					email: true,
					phone: {
						required: true,
						pattern: '^(?=.*[0-9].*[0-9].*[0-9].*[0-9])[0-9 ()+-.,/_]+$',
					},
					country: true,
					city: true,
					state: true,
					zip: true,
					address: true,
					payment_method: true,
					pm_id: true,
					portfolio: {
						require_from_group: [1, '.field_group'],
					},
					project_name: {
						require_from_group: [1, '.field_group'],
					},
					amount: {
						required: true,
						min: 1,
						number: true,
					},
					filesrc: {
						required: true,
					},
					bank_id: {
						required: true,
					},
					'amount-monthly': {
						required: true,
						min: 1,
						number: true,
					},
				},
				messages: {
					firstname: 'Please enter your firstname',
					lastname: 'Please enter your lastname',
					email: 'Please enter a valid email address',
					phone: 'Please enter a valid contact number.',
					country: 'Please select your country',
					city: 'Please enter your city',
					state: 'Please enter your state',
					zip: 'Please enter your zip/postal code',
					address: 'Please enter your address',
					payment_method: 'Please select your payment method',
					pm_id: 'Please enter your card details',
					amount: 'Please enter an amount',
					filesrc: 'Please upload a screenshot of the transfer',
					bank_id: 'Please select a bank',
					ach_account_type: 'Please select an account type',
					'amount-monthly': 'Please enter an amount',
				},
				errorPlacement: function (error, element) {
					if (element.attr('name') == 'frequency') {
						error.insertAfter(self.frequencyContainer + ' ul');
					} else if (element.attr('name') == 'presets') {
						error.insertAfter(self.presetsAmountContainer);
					} else if (element.attr('name') == 'fund') {
						error.insertAfter(self.fundContainer + ' ul');
					} else if (element.attr('name') == 'amount') {
						error.insertAfter(self.amountContainer);
					} else if (element.attr('name') == 'amount-monthly') {
						error.insertAfter(self.amountContainer);
					} else if (element.attr('name') == 'payment_method') {
						error.insertBefore(self.paymentMethodContainer + ' ul');
					} else if (element.attr('name') == 'pm_id') {
						error.insertAfter(self.cardContainer);
					} else if (element.attr('name') == 'filesrc') {
						error.insertAfter(self.uploadFile);
					} else if (element.attr('name') == 'bank_id') {
						error.insertAfter(self.bankDetails);
					} else if (element.attr('name') == 'product_quantity') {
						error.insertAfter(self.productQuantityContainer);
					} else if (element.attr('name') == 'ach_account_type') {
						$( element.parents('fieldset') ).append( error );
					} else {
						error.insertAfter(element);
					}
				},
				submitHandler: async function (form, event) {
					event.preventDefault();
					var formvalidated = self.verifyAllStepsValidated();
					$(document).trigger('tcf-form-payment-initiated');

					if (formvalidated) {
						self.formLoading(true);

						if (self.getSelectedPaymentMethod() == 'stripe') {
							let methodId = $(self.form).find("input[name='pm_id']").val();
							let donationId = $(self.form).find("input[name='donation_id']").val();

							if (donationId === undefined) {
								try {
									await self.createStripePaymentIntent(methodId);
								} catch (error) {
									self.formLoading(false);
									self.enablePaymentMethods();
									self.stopTimer();
									self.displayError(error);
									return false;
								}
							}
						}

						var formData = new FormData(document.querySelector(self.form));
						var jsonData = JSON.stringify(Object.fromEntries(formData.entries()));

						// Add elapsedTime to jsonData
						var dataObj = self.filterFormObj(JSON.parse(jsonData));
						dataObj.total_time_taken = self.elapsedTime;
						dataObj.debug = self.debugMode;

						// Add source information to jsonData
						dataObj.source_date = self.collectSourceDate();
						dataObj.source_url = self.collectSourceURL();

						// Add utm information to jsonData
						dataObj.utm_date = self.collectUTMDate();
						dataObj.utm_source = self.collectUTMSource();
						dataObj.utm_medium = self.collectUTMMedium();
						dataObj.utm_campaign = self.collectUTMCampaign();
						dataObj.utm_term = self.collectUTMTerm();
						dataObj.utm_content = self.collectUTMContent();

						if (!dataObj.payment_method) dataObj.payment_method = self.getSelectedPaymentMethod();

						if (!dataObj.country_code) dataObj.country_code = self.getSelectedCountryCode();

						if (self.stripeIntentData) dataObj.payment_intent = self.stripeIntentData;

						let recaptcha_token = await self.getRecaptchaToken('donation_form_submit');
						dataObj.g_recaptcha_response = recaptcha_token;

						//console.log(dataObj)
						//self.formLoading(false);
						//return false;

						jsonData = JSON.stringify(dataObj);

						try {
							let response = await $.ajax({
								type: 'POST',
								url: ajax_object.ajax_url,
								data: {
									action: 'donation_handler', //card submit
									data: jsonData,
								},
							});

							self.formLoading(false);
							self.stopTimer();
							var submittedData = JSON.parse(jsonData);

							if (response.donation_id) self.donation_id = response.donation_id;

							if (response.error) {
								self.formLoading(false);
								self.stopTimer();
								self.displayError('An error occurred during form submission, please check if you have filled all of the fields correctly');
							}

							if (response.success_noemail) {
								$(document).trigger('tcf-form-payment-success');

								if (response.donation_id) {
									self.redirectToThankyou(response.donation_id);
									return false;
								}
							}

							if (response.data) {
								$(document).trigger('tcf-form-payment-success');

								if (submittedData.payment_method == 'hbl') {
									self.redirectToHBLConfig(response);
									return false;
								}
							}

							if (response.success) {
								$(document).trigger('tcf-form-payment-success');

								if (submittedData.payment_method === 'cheque' || submittedData.payment_method === 'pk-bank-transfer') {
									self.redirectToThankyou(response.donation_id);
								}

								if (submittedData.payment_method == 'hbl') {
									self.redirectToHBLConfig(response);
									return false;
								}

								if (submittedData.payment_method === 'paypal') {
									self.redirectToThankyou('', response.success);
								}

								if (submittedData.payment_method === 'stripe') {
									self.redirectToThankyou(response.success.id);
								}

								if (submittedData.payment_method === 'google-pay') {
									self.redirectToThankyou(response.success.id);
								}

								if (submittedData.payment_method === 'apple-pay') {
									self.redirectToThankyou(response.success.id);
								}

								if (submittedData.payment_method === 'bank-transfer') {

									let paymentData = {};

									if ( self.getCurrentACHPaymentOption() == 'manual' ) {
										paymentData.payment_method = {
									      billing_details: {
									        name: submittedData.firstname,
									        email:  submittedData.email,
									      },
									      us_bank_account: {
									        account_number: submittedData.account_no,
									        routing_number: submittedData.route_no,
									        account_holder_type: submittedData.ach_account_type, // 'individual' or 'company'
									      },
									    }
									} 

									self.stripe.confirmUsBankAccountPayment(self.achPaymentIntent, paymentData).then(({ paymentIntent, error }) => {
										
										if (error) {
											// The payment failed for some reason.
											self.displayError(error.message);
											console.error(error.message);

										} else if (paymentIntent.status === 'requires_payment_method') {
											// Confirmation failed. Attempt again with a different payment method.

										} else if (paymentIntent.status === 'processing') {
											
											// Confirmation succeeded! The account will be debited.
											// Display a message to customer.
											self.redirectToThankyou(response.success.id);

										} else if (paymentIntent.next_action?.type === 'verify_with_microdeposits') {

											self.redirectToThankyou(response.success.id);
										}
									});
								}

								// Handle other payment methods
							} else {
								// Handle failure cases here
								if (submittedData.payment_method === 'stripe') {
									self.updateStripeError(response.error);
								}
							}
						} catch (error) {
							// Handle AJAX request error
							console.error(error);
							self.formLoading(false);
							self.stopTimer();
							self.displayError('An error occurred during form submission.');
						}
					}
				},
			});
		}

		filterFormObj(dataObj) {
			delete dataObj.currency_symbol;

			if (this.isGiftAidEnabled()) {
				if (dataObj.is_reclaim_giftaid == 0) {
					delete dataObj.giftaid;
					delete dataObj.giftaid_amount;
					delete dataObj.giftaid_billing_method;
					delete dataObj.bill_to_address_country_giftaid;
					delete dataObj.bill_to_address_postal_code_giftaid;
					delete dataObj.bill_to_address_city_giftaid;
					delete dataObj.bill_to_address_line1_giftaid;
					delete dataObj.bill_to_address_state_giftaid;
				} else {
					delete dataObj.giftaid;
					delete dataObj.giftaid_billing_method;

					if (this.getGiftAidBillingMethod() == 1) {
						dataObj.bill_to_address_country_giftaid = dataObj.country;
						dataObj.bill_to_address_postal_code_giftaid = dataObj.zip;
						dataObj.bill_to_address_city_giftaid = dataObj.city;
						dataObj.bill_to_address_line1_giftaid = dataObj.address;
						dataObj.bill_to_address_state_giftaid = dataObj.state;
					}
				}
			}

			return dataObj;
		}

		redirectToPage(url) {
			const self = this;

			$(self.successful).addClass('visible');
			$(self.form).hide();

			// Wait for 2 seconds
			setTimeout(function () {
				// Redirect to the thank you page
				var location = url;
				window.location.href = location;
			}, 1000);
		}

		redirectToThankyou(donationId, url) {
			const self = this;

			$(self.successful).addClass('visible');
			$(self.form).hide();

			// Wait for 2 seconds
			setTimeout(function () {
				// Redirect to the thank you page
				var debug = self.debugMode ? '&debug=true' : '';

				if ( self.getCustomThankyouURL() !== undefined ) {
					var location = self.getCustomThankyouURL() + '?id=' + self.urlEncodeBase64(donationId) + debug;
				} else {
					var location = !$.type(url) === 'undefined' || !$.isEmptyObject(url) ? url : '/thank-you?id=' + self.urlEncodeBase64(donationId) + debug;
				}

				
				window.location.href = location;
			}, 1000);
		}

		redirectToHBLConfig(responsed) {
			const self = this;
			let santizedData = responsed.data;

			//console.log(santizedData)
			//return;

			$(self.successful).addClass('visible');
			$(self.form).hide();

			var form = '';
			$.each(santizedData, function (key, value) {
				form += '<input type="hidden" name="' + key + '" value="' + value + '">';
			});
			var location = self.getHBLUrl();
			$('<form class="hbl-form" action="' + location + '" method="POST">' + form + '</form>').appendTo(this.formContainer);
			$('.hbl-form').submit();
		}

		verifyAllStepsValidated() {
			const self = this;

			if (self.stepsValidatedTill !== self.current) {
				self.goToStep(self.stepsValidatedTill);

				self.validate.form();

				return false;
			} else {
				return true;
			}
		}

		updateStates() {
			const self = this;

			$(self.countryField).change(function () {
				var value = $(this).val();
				var countryCode = $(self.countryField + ' option[value="' + value + '"]').attr('country_code');
				var states = self.getStates();
				var matchingStates = states.filter(function (state) {
					return state.country_code === countryCode;
				});
				$(self.stateField).empty();
				$(self.stateField).append($('<option>').text($(self.stateField).data('label')).attr('disabled', true).attr('selected', true));

				$.each(matchingStates, function (index, state) {
					$(self.stateField).append($('<option>').text(state.name).attr('value', state.name));
				});
			});

			//first load
			$(self.countryField).trigger('change');

			if (self.isGiftAidEnabled()) {
				$(self.giftAidCountryField).change(function () {
					var value = $(this).val();
					var countryCode = $(self.giftAidCountryField + ' option[value="' + value + '"]').attr('country_code');
					var states = self.getStates();
					var matchingStates = states.filter(function (state) {
						return state.country_code === countryCode;
					});

					$(self.giftAidStateField).empty();
					$(self.giftAidStateField).append(
						$('<option>')
							.text($(self.giftAidStateField).data('label'))

							.attr('disabled', true)

							.attr('selected', true),
					);

					$.each(matchingStates, function (index, state) {
						$(self.giftAidStateField).append($('<option>').text(state.name).attr('value', state.name));
					});
				});

				$(self.giftAidCountryField).trigger('change');
			}
		}

		stepperNavigation() {
			const self = this;

			$(self.stepTracker).click(function () {
				if (self.validate.form()) {
					var currentStep = parseInt($(this).attr('goToStep'));
					self.goToStep(currentStep);
				}
			});
		}

		backNavigation() {
			const self = this;

			$(self.backBtn).click(function () {
				var currentStep = parseInt($(this).attr('currentStep'));

				if (currentStep > 0) {
					currentStep--;
					// skip silent step
					$(self.step).each(function (index) {
						if (index === currentStep && $(this).hasClass('step-silent')) {
							currentStep--;
						}
					});

					if ( self.isACHOptionsBoxVisible() ) {
						self.changeACHNavigation(currentStep);
					} else {
						self.goToStep(currentStep);
					}
				}
			});
		}

		changeACHNavigation(currentStep) {
			let self = this;

			if ( $(self.achPaymentModeBox).is(':visible') ) {
				self.hideACHOptionsBox();
				self.showDonateBtnWrapper();
				self.changeCurrentStepTitle('Choose a payment method');
				self.showStepperNav()
				self.clearAllPaymentMethods();
			}


			if ( $(self.achManualOptionsBox).is(':visible') ) {
				self.removeStripeDataInputs();
				self.showACHPaymentModeBox();
				self.toggleACHBankOptionsBox();
				self.hideDonateBtnWrapper();
			}

		}

		isACHOptionsBoxVisible() {
			return $(this.achOptionsBox).hasClass('active');
		}

		// Function to navigate to a specific step in the accordion
		goToStep(step) {
			const self = this;

			if (self.accordion) {
				step = self.skipStepCheck(step);
				// Set the active step in the accordion an&&d update the current step value
				self.accordion.accordion('option', 'active', step);
				self.current = step;
				self.clearAllPaymentMethods();
				self.clearErrorContainer();

				// Show or hide the back button depending on whether the current step is 0
				if (step === 0) {
					$(self.backBtn).hide();
				} else {
					$(self.backBtn).show();
				}

				// Update the current step value in the back button's attribute
				$(self.backBtn).attr('currentStep', step);

				// Add the 'active' class to the step-tracker element corresponding to the current step
				$(self.stepTracker).removeClass('active');

				$(self.stepTracker + "[goToStep='" + self.current + "']").addClass('active');

				self.populateTitles(step);

				if (step !== 0) {
					self.scrolltoForm();
				}

				if (step == self.getNumberOfSteps() - 2) {
					if (!self.stripe) {
						self.loadStripe();
					}

					if (self.stripe) {
						self.makeAppleGooglePaymentRequest();
					}
				}

				$(document).trigger('tcf-form-step-change', [this.current]);
			} else {
				console.log('Accordion is not defined');
			}
		}

		scrolltoForm() {
			$('html, body').animate(
				{
					scrollTop: $(this.formContainer).offset().top - 50,
				},
				200,
				'swing', // Adjust the scroll animation speed as desired
			);
		}

		addStepstoButtons() {
			const self = this;

			if ($(self.step).length) {
				var counter = 1;

				$(self.step).each(function () {
					if (!$(this).is(':last-child')) {
						var $button = $(this).find('button');

						$button.attr('gotostep', counter);

						counter++;
					}
				});
			}
		}

		buttonNavigation() {
			const self = this;

			$(self.butttonStepper).on('click', function (event) {
				event.preventDefault();

				if (self.validate.form()) {
					var nextStep = parseInt($(this).attr('gotostep'));
					self.stepsValidatedTill = nextStep;
					self.goToStep(nextStep);
				}
			});
		}

		showCorporateMatchingOrganization() {
			const self = this;

			$(self.corporateMatchingCheckbox).change(function () {
				if ($(this).is(':checked')) {
					$(self.corporateMatchingTextBox).parent().show();

					$(self.corporateMatchingTextBox).prop('required', true);
				} else {
					$(self.corporateMatchingTextBox).parent().hide();

					$(self.corporateMatchingTextBox).prop('required', false);

					$(self.corporateMatchingTextBox).val('');
				}
			});
		}

		showPortfolioField() {
			const self = this;

			$(self.portfolioCheckbox).change(function () {
				if ($(this).is(':checked')) {
					$(self.portfolioSelect).parent().show();

					$(self.projectField).parent().show();
				} else {
					$(self.portfolioSelect).parent().hide();

					$(self.projectField).parent().hide();

					$(self.projectField).val('');

					$(self.portfolioSelect).val('');
				}
			});
		}

		showProjectField() {
			const self = this;

			$(self.projectCheckbox).change(function () {
				if ($(this).is(':checked')) {
					$(self.projectField).parent().show();

					$(self.projectField).prop('required', true);
				} else {
					$(self.projectField).parent().hide();

					$(self.projectField).prop('required', false);
				}
			});
		}

		formHelper() {
			const self = this;
			const $helper = $(self.getUniqueFormElements('.helper'));
			const $helperLink = $helper.find('.helper-link');
			let currentHelper;

			$helper.on('click', function (e) {
				if (!$(e.target).closest($helperLink).length) {
					e.preventDefault();
					$(this).find('.content').toggleClass('active');
					currentHelper = this;
				}
			});

			$(document).on('click', function (e) {
				if (currentHelper && !$(currentHelper).is(e.target) && $(currentHelper).has(e.target).length === 0) {
					$(currentHelper).find('.content').removeClass('active');
					currentHelper = null;
				}
			});
		}

		formstartTimer() {
			const self = this;

			$(this.formContainer).on('click', function () {
				if (!self.timerInterval) {
					self.startTime = Date.now();

					self.timerInterval = setInterval(function () {
						self.elapsedTime = Math.floor((Date.now() - self.startTime) / 1000);
					}, 1000);
				}
			});
		}

		stopTimer() {
			clearInterval(this.timerInterval);
		}

		appendRequired() {
			$(this.getUniqueFormElements('label.required')).append('&nbsp;<strong>*</strong>&nbsp;');
		}

		queueStripe() {
			const self = this;

			$(self.paymentMethod).on('change', function () {
				const paymentMethod = $(this).val();
				self.clearOtherPaymentMethods(paymentMethod);

				if (paymentMethod === 'stripe') {
					self.loadStripe('CARD');
				} else if (paymentMethod === 'bank-transfer') {
					
					self.showACHOptionsBox();
					self.showAchManualBtn();
					self.changeCurrentStepTitle('Bank Transfer');
					self.hideStepperNav();

					//self.loadStripe('ACH');
				} else if (paymentMethod === 'apple-pay') {
					self.loadStripe('APAY');
				} else if (paymentMethod === 'google-pay') {
					self.loadStripe('GPAY');
				} else if (paymentMethod === 'paypal') {
					self.toggleTransactionFeeVisibility(true);
				} else if (paymentMethod === 'cheque') {
					self.mountCheque();
				} else if (paymentMethod === 'pk-bank-transfer') {
					self.mountPkBankTransfer();
				} else if (paymentMethod === 'hbl') {
					self.mountHblTransfer();
				}
			});
		}

		loadStripe(type = false) {
			const self = this;

			if (!self.shouldLoadStripe()) return;

			if (!self.stripe) {
				self.stripe = null;

				let stripeScript = document.querySelector("script[src='" + this.stripeSrc + "']");

				if (!stripeScript) {
					stripeScript = document.createElement('script');
					stripeScript.src = this.stripeSrc;
					document.body.appendChild(stripeScript);
				} else {
					self.stripe = Stripe(self.returnStripeKey());
					self.mountPaymentMethodElements(type);
				}

				if (self.isDailyOnly()) {
					$(this.getUniqueFormElements('.stripe')).show();
					$(this.getUniqueFormElements('.bank-transfer')).hide();
					$(this.getUniqueFormElements('.paypal')).hide();
					$(this.getUniqueFormElements('.google-pay')).hide();
					$(this.getUniqueFormElements('.apple-pay')).hide();
				}

				stripeScript.onload = function () {
					self.stripe = Stripe(self.returnStripeKey());
					self.mountPaymentMethodElements(type);

					// make gpay and apay payment request
					if (!self.stripePaymentRequest && !self.isDailyOnly()) {
						self.makeAppleGooglePaymentRequest();
					}
				};
			} else {
				self.mountPaymentMethodElements(type);
			}
		}

		shouldLoadStripe() {
			let self = this;
			if (self.getChapterCountryCode() == 'PK' || self.getChapterCountryCode() == 'AE') return false;
			return true;
		}

		mountPaymentMethodElements(paymentMethod) {
			let self = this;

			if (paymentMethod === 'ACH') {
				self.mountACH();
			}

			if (paymentMethod === 'CARD') {
				self.mountCard();
			}

			if (paymentMethod === 'GPAY') {
				self.mountGPAY();
			}
			if (paymentMethod === 'APAY') {
				self.mountAPAY();
			}
		}

		clearOtherPaymentMethods(paymentMethod) {
			const self = this;

			if (paymentMethod !== 'stripe') {
				if (self.cardElement) {
					self.cardElement.unmount();
					self.cardElement.destroy();
					self.cardElement = null;
				}

				self.hideCardField();
			}

			if (paymentMethod !== 'bank-transfer') {
				self.hideAchField();
				self.hideAchMandate();
			}

			self.removeStripeDataInputs();
			self.hideExpressCheckoutButton();
			self.showDonateBtn();
			self.clearErrorContainer();
			self.toggleTransactionFeeVisibility(false);
		}

		clearAllPaymentMethods() {
			const self = this;

			if (self.cardElement) {
				self.cardElement.unmount();
				self.cardElement.destroy();
				self.cardElement = null;
			}

			self.unselectAllPaymentGateways();
			self.clearSelectedPaymentMethod();
			self.removeStripeDataInputs();
			self.hideCardField();
			self.hideAchField();
			self.hideAchMandate();
			self.hideExpressCheckoutButton();
			self.showDonateBtn();
			self.clearErrorContainer();
			self.toggleTransactionFeeVisibility(false);
		}

		mountACH() {
			const self = this;
			var formvalidated = self.verifyAllStepsValidated();

			if (formvalidated) {
				self.formLoading(true);
				self.showAchLoader();
				self.disablePaymentMethods();
				self.toggleTransactionFeeVisibility(false);

				let formData = self.getFormData();

				$.ajax({
					type: 'POST',
					url: ajax_object.ajax_url,
					data: {
						action: 'intent_handler',
						data: JSON.stringify(formData),
					},
					success: function (response) {

						// hide button loader
						self.toggleACHBtnLoader();

						self.formLoading(false);
						self.achPaymentIntent = response.success.client_secret;
						self.achPaymentMethod = response.success.payment_method;
						self.achInvoiceid = response.success.invoice_id;
						self.achStripe_sub_id = response.success.stripe_sub_id;
						self.achsSartdate = response.success.start_date;
						self.achEnddate = response.success.end_date;

						if (self.getSelectedFrequency() == 'monthly') {
							$(self.form).append('<input type="hidden" name="invoice_id" value="' + self.achInvoiceid + '">');
							$(self.form).append('<input type="hidden" name="stripe_sub_id" value="' + self.achStripe_sub_id + '">');
							$(self.form).append('<input type="hidden" name="start_date" value="' + self.achsSartdate + '">');
							$(self.form).append('<input type="hidden" name="end_date" value="' + self.achEnddate + '">');
						}

						if (!self.achPaymentMethod) {
							
							// collect bank info if automatic
							if ( self.getCurrentACHPaymentOption() == 'automatic' ) {
								// Calling this method will open the instant verification dialog.
								self.stripe.collectBankAccountForPayment({
									clientSecret: self.achPaymentIntent,
									params: {
										payment_method_type: 'us_bank_account',
										payment_method_data: {
											billing_details: {
												name: self.getDonorFullName(),
												email: self.getDonorEmail(),
											},
										},
									},
									expand: ['payment_method'],
								}).then(({ paymentIntent, error }) => {
									
									if (error) {
										console.error(error.message);
										self.displayError(error.message);
										// PaymentMethod collection failed for some reason.
									} else if (paymentIntent.status === 'requires_payment_method') {
								
										// Customer canceled the hosted verification modal. Present them with other
										// payment method type options.
										//self.hideAchField();

									} else if (paymentIntent.status === 'requires_confirmation') {
										self.appendStripeDataInputs(paymentIntent.id, response);
										const bankName = paymentIntent.payment_method.us_bank_account.bank_name;
										const bankNumber = paymentIntent.payment_method.us_bank_account.last4;
										self.showAchField(bankName, bankNumber);
										self.toggleTransactionFeeVisibility(false);
										self.showDonateBtnWrapper();
										self.hideAchManualBtn();
									}
								}); 
							}

							if ( self.getCurrentACHPaymentOption() == 'manual' ) {
								self.appendStripeDataInputs(false, response);
								self.hideACHPaymentModeBox();
								self.toggleACHBankOptionsBox();
								self.showDonateBtnWrapper();
							}

						} else {
							$(self.form).find('input[name="client_secret"]').val(self.achPaymentIntent);
							const bankName = self.achPaymentMethod.bank_account.bank_name;
							const bankNumber = self.achPaymentMethod.bank_account.last4;
							self.showAchField(bankName, bankNumber);
						}

						self.hideAchLoader();
						self.enablePaymentMethods();
						self.showAchMandate();
					},

					error: function (xhr, status, error) {
						// handle errors here
						self.formLoading(false);
						self.stopTimer();
					},
				});
			}
		}

		mountAPAY() {
			const self = this;
			var formvalidated = self.verifyAllStepsValidated();

			if (formvalidated) {
				self.formLoading(true);
				self.showAppleLoader();
				self.disablePaymentMethods();
				self.toggleTransactionFeeVisibility(false);

				const elements = self.stripe.elements({
					fonts: [
						{
							cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
						},
					],
				});

				let expressCheckoutBtn = $(self).find('#express-checkout-element');

				if (expressCheckoutBtn.length === 0) {
					const prButton = elements.create('paymentRequestButton', {
						paymentRequest: self.stripePaymentRequest,
						style: {
							paymentRequestButton: {
								type: 'donate',
								theme: 'dark',
								height: '44px',
							},
						},
					});

					prButton.mount('#express-checkout-element');
					prButton.on('ready', function (event) {
						self.formLoading(false);
						self.hideAppleLoader();
						self.hideDonateBtn();
						self.showExpressCheckoutButton();
						self.toggleTransactionFeeVisibility(true);
						return;
					});
				} else {
					self.formLoading(false);
					self.hideAppleLoader();
					self.hideDonateBtn();
					self.enablePaymentMethods();
					self.showExpressCheckoutButton();
				}

				// Handle the payment when the user confirms
				self.stripePaymentRequest.on('paymentmethod', async function (ev) {
					self.formLoading(true);
					self.showAppleLoader();

					var methodId = ev.paymentMethod.id;

					try {
						await self.createStripePaymentIntent(methodId, 'Apple');
					} catch (error) {
						// ev.complete('fail') does not close the popup;
						ev.complete('success');

						self.hideAppleLoader();
						self.enablePaymentMethods();
						self.stopTimer();
						self.displayError(error);
						self.formLoading(false);
						return false;
					}

					ev.complete('success');
					self.hideExpressCheckoutButton();
					self.showDonateBtn();
					self.formLoading(false);
					$(self.form).submit();
				});

				self.stripePaymentRequest.on('cancel', async function (ev) {
					self.formLoading(false);
					self.hideAppleLoader();
					self.enablePaymentMethods();
					self.clearAllPaymentMethods();
				});
			}
		}

		mountGPAY() {
			const self = this;
			var formvalidated = self.verifyAllStepsValidated();

			if (formvalidated) {
				self.formLoading(true);
				self.showGoogleLoader();
				self.disablePaymentMethods();
				self.toggleTransactionFeeVisibility(false);

				const elements = self.stripe.elements({
					fonts: [
						{
							cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
						},
					],
				});

				let expressCheckoutBtn = $(self).find('#express-checkout-element');

				if (expressCheckoutBtn.length === 0) {
					const prButton = elements.create('paymentRequestButton', {
						paymentRequest: self.stripePaymentRequest,
						style: {
							paymentRequestButton: {
								type: 'donate',
								theme: 'dark',
								height: '44px',
							},
						},
					});

					prButton.mount('#express-checkout-element');
					prButton.on('ready', function (event) {
						self.formLoading(false);
						self.hideGoogleLoader();
						self.hideDonateBtn();
						self.showExpressCheckoutButton();
						self.enablePaymentMethods();
						self.toggleTransactionFeeVisibility(true);
						return;
					});
				} else {
					self.formLoading(false);
					self.hideGoogleLoader();
					self.hideDonateBtn();
					self.enablePaymentMethods();
					self.showExpressCheckoutButton();
				}

				// Handle the payment when the user confirms
				self.stripePaymentRequest.on('paymentmethod', async function (ev) {
					self.formLoading(true);
					self.showGoogleLoader();

					var methodId = ev.paymentMethod.id;

					try {
						await self.createStripePaymentIntent(methodId, 'Google');
					} catch (error) {
						// ev.complete('fail') does not close the popup;
						ev.complete('success');

						self.hideGoogleLoader();
						self.enablePaymentMethods();
						self.stopTimer();
						self.displayError(error);
						self.formLoading(false);
						return false;
					}

					ev.complete('success');
					self.hideExpressCheckoutButton();
					self.showDonateBtn();
					self.formLoading(false);
					$(self.form).submit();
				});

				self.stripePaymentRequest.on('cancel', async function (ev) {
					self.formLoading(false);
					self.hideGoogleLoader();
					self.enablePaymentMethods();
					self.clearAllPaymentMethods();
				});
			}
		}

		makeAppleGooglePaymentRequest() {
			const self = this;

			self.disablePaymentMethods();

			var data = {
				country: self.getChapterCountryCode(),
				currency: self.getSelectedCurrency().toLowerCase(),
				total: {
					label: 'total',
					amount: self.getSelectedAmount() * 100,
				},
				requestPayerName: true,
				requestPayerEmail: true,
				disableWallets: ['link'],
			};

			this.logGroup('GPAY Initiated', data, 'makeAppleGooglePaymentRequest');

			self.stripePaymentRequest = null;
			self.stripePaymentRequest = self.stripe.paymentRequest(data);

			self.showGoogleLoader();
			self.showAppleLoader();

			self.stripePaymentRequest.canMakePayment().then(function (result) {
				if (!result) {
					self.hideAPayPM();
					self.hideGPayPM();
				} else {
					if (!result.googlePay) self.hideGPayPM();

					if (!result.applePay) self.hideAPayPM();
				}

				self.hideGoogleLoader();
				self.hideAppleLoader();
				self.enablePaymentMethods();

				return;
			});
		}

		async createStripePaymentIntent(paymentMethodId = false, device = '') {
			if (!paymentMethodId) throw 'No payment method provided';

			let self = this;
			let formData = self.getFormData();
			let recaptcha_token = await self.getRecaptchaToken('intent_handler');
			formData.g_recaptcha_response = recaptcha_token;
			formData.pm_id = paymentMethodId;

			if (device == 'Apple' || device == 'Google') {
				formData.is_google_apple_pay = true;
				formData.google_apple_device_name = device;
			}

			try {
				let response = await $.ajax({
					type: 'POST',
					url: ajax_object.ajax_url,
					data: {
						action: 'intent_handler',
						data: JSON.stringify(formData),
					},
				});

				if (response.error != null) {
					self.stripeErrorMessage = response.error;
					throw response.error;
				}

				if ((self.getSelectedFrequency() == 'monthly') || (self.getSelectedFrequency() == 'daily' )) {
					var client_secret = response.success.payment_intent !== null ? response.success.payment_intent : null;
				} else {
					var client_secret = response.success.client_secret;
				}

				if ((self.getSelectedFrequency() === 'daily') && (client_secret == null) ) {
					await self.appendStripeDataInputs(paymentMethodId, response);
					return true;
				}

				if (response.success.payment_status !== undefined) {
					let status = response.success.payment_status.status;
					let paymentIntent = response.success.payment_status;

					if (status === 'succeeded') {
						await self.appendStripeDataInputs(paymentMethodId, response, paymentIntent);
						return true;
					}
				}

				const { paymentIntent, error: confirmError } = await self.stripe.confirmCardPayment(
					client_secret,
					{
						payment_method: paymentMethodId,
						return_url: window.location.href,
					},
					{ handleActions: false },
				);

				if (confirmError) {
					self.stripeErrorMessage = confirmError.message;
					throw confirmError.message;
				} else {
					if (paymentIntent.status === 'succeeded') {
						await self.appendStripeDataInputs(paymentMethodId, response, paymentIntent);
						return true;
					} else if (paymentIntent.status === 'requires_action') {
						var action = paymentIntent.next_action;
						var iframe = document.createElement('iframe');
						iframe.src = paymentIntent.next_action.redirect_to_url.url;
						$(self.authenticationModal).append(iframe);
						$(self.authenticationModal).dialog({
							modal: true,
							closeOnEscape: false,
						});
						$(self.authenticationModal).dialog('open');

						await self.appendStripeDataInputs(paymentMethodId, response, paymentIntent);
						throw 'Awaiting Authentication';
					} else {
						throw 'Payment intent not succeeded';
					}
				}
			} catch (error) {
				// handle errors here
				self.formLoading(false);
				self.stripeErrorMessage = error;
				throw error;
			}
		}

		mountCard() {
			const self = this;
			self.showCardLoader();
			self.disablePaymentMethods();

			self.elements = self.stripe.elements();
			self.cardElement = self.elements.create('card', {
				hidePostalCode: true,
				style: {
					base: {
						iconColor: '#00884c',
						fontWeight: '500',
						fontSize: '16px',
						color: '#bcbcbc',
					},
					invalid: {
						iconColor: '#FFC7EE',
						color: '#FFC7EE',
					},
				},
			});

			self.cardElement.mount(self.cardContainer);
			self.showCardField();
			self.hideCardLoader();
			self.enablePaymentMethods();

			// append empty 'pm_id' for validation check
			self.appendStripeDataInputs();

			if (typeof self.cardElement !== 'undefined') {
				self.cardElement.on('change', async function (event) {
					if (event.complete) {
						self.showCardLoader();
						self.formLoading(true);
						self.clearErrorContainer();

						self.stripe
							.createPaymentMethod({
								type: 'card',
								card: self.cardElement,
							})
							.then(async function (result) {
								if (result.error) {
									let error = result.error;
									self.displayError(error.message);
									self.hideCardLoader();
									self.formLoading(false);
									throw error;
								} else {
									const methodId = result.paymentMethod.id;
									self.appendStripeDataInputs(methodId);
									self.toggleTransactionFeeVisibility(true);
									self.hideCardLoader();
									self.formLoading(false);
								}
							})
							.catch((error) => console.log('error', error));
					}
				});
			}
		}

		mountCheque() {
			const self = this;
			var formvalidated = self.verifyAllStepsValidated();
			if (formvalidated) {
				self.showCardLoader();
				self.showChequeField();
				self.hideScreenShotField();
			}

			// self.disablePaymentMethods();
		}

		mountHblTransfer() {
			const self = this;
			var formvalidated = self.verifyAllStepsValidated();
			if (formvalidated) {
				self.showCardLoader();
				self.hideChequeField();
				self.hideScreenShotField();
				// self.disablePaymentMethods();
				$(self.HblContainer).append('<input type="hidden" name="payment_method" value="hbl">');
				$(self.HblContainer).append('<input type="hidden" name="hbl_config" value="yes">');
				self.toggleTransactionFeeVisibility(true);
			}
		}

		mountPkBankTransfer() {
			const self = this;

			var formvalidated = self.verifyAllStepsValidated();

			if (formvalidated) {
				self.showCardLoader();
				self.showScreenShotField();
				self.hideChequeField();
				self.HideListDetails();
				$(self.filelist)
					.filter('[data-bank="' + $(self.bankDetails).val() + '"]')
					.show();

				$(self.form).append('<input type="text" name="bank_id" value="" class="hidden-input" required>');
				$(self.form).append('<input type="text" name="filesrc" value="" class="hidden-input" required>');

				$(self.bankDetails).change(function () {
					self.showFileBtn();
					self.HideListDetails();
					$(self.filelist)
						.filter('[data-bank="' + $(this).val() + '"]')
						.show();
					$(self.bankIdField).val($(this).val());
				});

				$(self.fileButton).on('click', function () {
					$(self.uploadFile).click();
				});

				$(self.uploadFile).on('change', function () {
					var fileInput = this;
					var selectedFile = fileInput.files[0];
					var visibleBankValue = $('ul li:visible')
						.map(function () {
							return $(this).data('bank');
						})
						.get();

					// Display preview for image files
					if (selectedFile && selectedFile.type && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
						var reader = new FileReader();
						reader.onload = function (e) {
							$(self.previewImage).attr('src', e.target.result);
							self.showFilePreview();
							$(self.bankScreenshotField).val(e.target.result);
						};

						reader.readAsDataURL(selectedFile);
					} else {
						self.displayError('Please select a valid JPG or PNG image file.');
						$(self.previewImage).attr('src', '');
						self.hideFilePreview();
					}
				});
			}
		}

		appendStripeDataInputs(methodId = false, data = null, paymentIntent = null) {
			let self = this;

			// clear old data if its already populated
			self.removeStripeDataInputs();

			if (paymentIntent != null) self.stripeIntentData = paymentIntent;

			if (data != null) {
				let obj = data.success;
				let client_secret = obj.payment_intent ? obj.payment_intent : obj.client_secret ? obj.client_secret : null;
				let invoice_id = obj.invoice_id;
				let stripe_sub_id = obj.stripe_sub_id;
				let start_date = obj.start_date;
				let end_date = obj.end_date;
				let donation_id = obj.donation_id;

				if (client_secret) {
					$(self.form).append('<input type="text" name="client_secret" value="' + client_secret + '" required class="hidden-input">');
				}

				if (donation_id) {
					$(self.form).append('<input type="text" name="donation_id" value="' + donation_id + '" required class="hidden-input">');
				}

				if (invoice_id) {
					$(self.form).append('<input type="hidden" name="invoice_id" value="' + invoice_id + '">');
				}

				if (stripe_sub_id) {
					$(self.form).append('<input type="hidden" name="stripe_sub_id" value="' + stripe_sub_id + '">');
				}

				if (start_date) {
					$(self.form).append('<input type="hidden" name="start_date" value="' + start_date + '">');
				}

				if (end_date) {
					$(self.form).append('<input type="hidden" name="end_date" value="' + end_date + '">');
				}
			}

			if (methodId) {
				$(self.form).append('<input type="text" name="pm_id" value="' + methodId + '" required class="hidden-input">');
			} else {
				$(self.form).append('<input type="text" name="pm_id" value="" class="hidden-input">');
			}
		}

		removeStripeDataInputs() {
			let self = this;

			if (self.stripeIntentData != 'undefined') {
				self.stripeIntentData = '';
			}

			if ($(self.form).find('input[name="client_secret"]').length > 0) {
				$(self.form).find('input[name="client_secret"]').remove();
			}

			if ($(self.form).find('input[name="pm_id"]').length > 0) {
				$(self.form).find('input[name="pm_id"]').remove();
			}

			if ($(self.form).find('input[name="donation_id"]').length > 0) {
				$(self.form).find('input[name="donation_id"]').remove();
			}

			if ($(self.form).find('input[name="invoice_id"]').length > 0) {
				$(self.form).find('input[name="invoice_id"]').remove();
			}

			if ($(self.form).find('input[name="stripe_sub_id"]').length > 0) {
				$(self.form).find('input[name="stripe_sub_id"]').remove();
			}

			if (!self.isDailyOnly()) {
				if ($(self.form).find('input[name="start_date"]').length > 0) {
					$(self.form).find('input[name="start_date"]').remove();
				}

				if ($(self.form).find('input[name="end_date"]').length > 0) {
					$(self.form).find('input[name="end_date"]').remove();
				}
			}

			if ($(self.form).find('input[name="bank_id"]').length > 0) {
				$(self.form).find('input[name="bank_id"]').remove();
			}

			if ($(self.form).find('input[name="filesrc"]').length > 0) {
				$(self.form).find('input[name="filesrc"]').remove();
			}
		}

		updateStripeError(message) {
			var div = $('<div>').text(message).addClass('stripe-error');

			// Append the div to the parent element
			$(this.cardContainer).append(div);
		}

		returnStripeKey() {
			let key = '';
			var pmKeys = this.getPmKeys();
			const self = this;
			// console.log('Selected Country ID: '+self.getChapterId());
			$.each(pmKeys, function (index, paymentMethod) {
				if (paymentMethod.name === 'stripe' && paymentMethod.country_id == self.getChapterId()) {
					key = paymentMethod.public_key;

					return false; // exit the loop
				}
			});

			return key;
		}

		showCardField() {
			$(this.cardContainer).show();
			$(this.getUniqueFormElements('.stripe')).addClass('loaded');

			if (typeof this.cardElement !== 'undefined') {
				this.cardElement.on('ready', () => {
					this.cardElement.focus();
				});
			}
		}
		showChequeField() {
			$(this.ChequeContainer).show();
		}

		hideChequeField() {
			$(this.ChequeContainer).hide();
		}

		showScreenShotField() {
			$(this.FileContainer).show();
		}

		hideScreenShotField() {
			$(this.FileContainer).hide();
		}

		HideListDetails() {
			$(this.filelist).hide();
		}

		showFilePreview() {
			$(this.filePreview).show();
		}

		hideFilePreview() {
			$(this.filePreview).show();
		}

		showFileBtn() {
			$(this.fileButton).show();
		}
		showAccountDetails() {
			$(this.accountList).show();
		}

		hideScreenShotField() {
			$(this.FileContainer).hide();
		}

		hideCardField() {
			$(this.cardContainer).hide();
			$(this.cardField).prop('checked', false);
		}

		hideAchPM() {
			const self = this;

			let frequency = self.getSelectedFrequency();
			if (frequency === 'monthly') {
				$(self.achPMContainer).hide();
			}

			$(this.frequencyElements).on('change', function () {
				if ($(this).val() == 'monthly') {
					$(self.achPMContainer).hide();
				} else {
					$(self.achPMContainer).show();
				}
			});
		}

		unselectAllPaymentGateways() {
			$(this.getUniqueFormElements('.stripe')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.bank-transfer')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.paypal')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.google-pay')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.apple-pay')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.hbl')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.pk-bank-transfer')).removeClass(['loaded', 'active']);
			$(this.getUniqueFormElements('.cheque')).removeClass(['loaded', 'active']);
		}

		hideAPayPM() {
			$(this.aPayContainer).hide();
		}

		hideGPayPM() {
			$(this.gPayContainer).hide();
		}

		hideChequePM() {
			$(this.chequeContainerSelect).hide();
		}

		showChequePM() {
			$(this.chequeContainerSelect).show();
		}

		hideBankTransferPM() {
			$(this.bankTransferContainer).hide();
		}

		showBankTransferPM() {
			$(this.bankTransferContainer).show();
		}

		hideAchField() {
			$(this.achContainer).hide();
			$(this.achField).prop('checked', false);
		}

		showAchField(bankName, bankNumber) {
			$(this.achBankName).text(bankName);
			$(this.achBankNumber).text('••••' + bankNumber);
			$(this.achContainer).show();
			$(this.getUniqueFormElements('.bank-transfer')).addClass('loaded');
		}

		showCardLoader() {
			$(this.getUniqueFormElements('.payment_methods .stripe .loading-icon')).show();
		}

		hideCardLoader() {
			$(this.getUniqueFormElements('.payment_methods .stripe .loading-icon')).hide();
		}

		showAchLoader() {
			$(this.getUniqueFormElements('.payment_methods .bank-transfer .loading-icon')).show();
		}

		showAppleLoader() {
			$(this.getUniqueFormElements('.payment_methods .apple-pay .loading-icon')).show();
		}

		hideAppleLoader() {
			$(this.getUniqueFormElements('.payment_methods .apple-pay .loading-icon')).hide();
		}

		showGoogleLoader() {
			$(this.getUniqueFormElements('.payment_methods .google-pay .loading-icon')).show();
		}

		hideGoogleLoader() {
			$(this.getUniqueFormElements('.payment_methods .google-pay .loading-icon')).hide();
		}

		hideAchLoader() {
			$(this.getUniqueFormElements('.payment_methods .bank-transfer .loading-icon')).hide();
		}

		showAchMandate() {
			$(this.getUniqueFormElements('.ach-mandate')).show();
		}

		hideAchMandate() {
			$(this.getUniqueFormElements('.ach-mandate')).hide();
		}

		hideDonateBtn() {
			$(this.buttton).hide();
		}

		showDonateBtn() {
			$(this.buttton).show();
		}

		hideDonateBtnWrapper() {
			$(this.donateButtonwrapper).hide();
		}

		showDonateBtnWrapper() {
			$(this.donateButtonwrapper).show();
		}

		showExpressCheckoutButton() {
			$('#express-checkout-element').show();
		}

		hideExpressCheckoutButton() {
			$('#express-checkout-element').hide();
		}

		disablePaymentMethods() {
			$(this.paymentMethod).attr('disabled', true);
		}

		enablePaymentMethods() {
			$(this.paymentMethod).attr('disabled', false);
		}

		showTransactionFee() {
			const self = this;

			// Update the transaction fee text initially
			self.updateTransactionFeeText();

			// Event handler for the click event on the payment method
			$(this.paymentMethod).click(function () {
				// Update the transaction fee text when the payment method is clicked

				self.updateTransactionFeeText();

				// Reset the consume fee checkbox if it is checked
				if ($(self.consumeFee).is(':checked')) {
					$(self.consumeFee).prop('checked', false).trigger('change');
				}
			});
		}

		updateTransactionFeeText() {
			const isValidAmount = this.isValidAmount();

			const isValidFee = this.isValidFee();

			const isPaymentMethodSelected = this.isPaymentMethodSelected();

			// Toggle the visibility of the transaction fee based on validity conditions
			this.toggleTransactionFeeVisibility(isValidAmount && isPaymentMethodSelected && isValidFee);

			if (isValidAmount && isPaymentMethodSelected && isValidFee) {
				// Show the transaction fee text if all validity conditions are met
				this.showTransactionFeeText();
			}
		}

		populateTransactionFee() {
			const self = this;

			// Event handler for the change event on the consume fee checkbox
			$(self.consumeFee).change(function () {
				const isValidAmount = self.isValidAmount();

				const isValidFee = self.isValidFee();

				const isPaymentMethodSelected = self.isPaymentMethodSelected();

				if (isPaymentMethodSelected && isValidAmount && isValidFee) {
					if ($(this).is(':checked')) {
						// Adjust the transaction fee amount when the consume fee checkbox is checked

						self.adjustTransactionFeeAmount();
					} else {
						// Subtract the transaction fee amount when the consume fee checkbox is unchecked

						self.adjustTransactionFeeAmount('subtract');
					}
				}
			});
		}

		adjustTransactionFeeAmount(method) {
			var newAmount = null;

			const currentAmount = this.getSelectedAmount();
			var transactionFee = this.calculateTransactionFee();

			if (method == 'subtract') {
				// Subtract the transaction fee amount from the current amount

				this.updateTransactionFeeAmount(
					currentAmount - transactionFee,

					'',
				);
			} else {
				// Add the transaction fee amount to the current amount

				this.updateTransactionFeeAmount(
					currentAmount + transactionFee,

					transactionFee,
				);
			}
		}

		calculateTransactionFee() {
			var result = 0;

			var amount = this.getSelectedAmount();
			var percentage = this.getTransationFeePercentage();
			var current = this.getCurrentTransactionFee();

			// Calculate the transaction fee based on the selected amount and fee percentage
			if (!isNaN(amount) && !isNaN(percentage) && isNaN(current)) {
				result = this.convertToNum((amount * percentage) / 100);
			} else {
				result = current;
			}

			return result;
		}

		showTransactionFeeText() {
			var percentage = this.getTransationFeePercentage();

			var amount = this.calculateTransactionFee();

			const text = 'Yes! Make my donation go further by covering the ' + percentage + '% processing fee. (' + this.getCurrencySymbol() + amount + ')';

			// Display the transaction fee text
			$(this.consumeFeeText).text(text);
		}

		toggleTransactionFeeVisibility(show) {
			// console.log("toggle", show)
			// Toggle the "hidden" class based on the show parameter
			$(this.transactionFee).toggleClass('hidden', !show);

			if (show === false) {
				// Uncheck transaction fee if checked before
				$(this.consumeFee).prop('checked', false);
				this.resetTransactionFee();
			}
		}

		updateTransactionFeeAmount(newAmount, transactionFee) {
			// Update the transaction fee amount and the overall amount field
			$(this.transactionFeeAmount).val(transactionFee);

			this.setAmountValue(this.convertToNum(newAmount));
		}

		updateDonateButtonText() {
			const self = this;
			var amountOnetime = document.querySelector(this.amountField);
			var amountMonthly = document.querySelector(this.monthlyAmountField);
			var donateButton = document.querySelector(self.donateButtonText);
			var donateButtonText = donateButton.textContent;
			var stepButton = document.querySelector(self.stepButton);

			var initialstepButtonText = stepButton.textContent;

			if (amountOnetime != null) {
				amountOnetime.addEventListener('change', function (event) {
					if (self.isDailyOnly()) {
						donateButton.textContent = self.constructButtonText(self.getFormattedAmount(), '', false, true);
					} else {
						donateButton.textContent = self.constructButtonText(self.getFormattedAmount());
					}

					self.updateStepButtonText(false);
				});
			}

			if (amountMonthly != null) {
				amountMonthly.addEventListener('change', function (event) {
					let buttonText = self.constructButtonText(self.getFormattedAmount(), '', true);
					donateButton.textContent = buttonText;
					self.updateStepButtonText(true);
				});
			}
		}

		updateStepButtonText(monthly = false) {
			const self = this;
			var stepButtons = document.querySelectorAll(self.stepButton);
			stepButtons.forEach(function (stepButton) {
				if (stepButton.classList.contains('show-amount')) {
					const stepButtonNewText = self.constructButtonText(self.getFormattedAmount(), 'with ', monthly);
					stepButton.textContent = stepButtonNewText;
				}
			});
		}

		constructButtonText(amount, addBefore = '', monthly = false, daily = false) {
			if (monthly) {
				return addBefore + amount + ' ' + ajax_object.text_strings.monthly;
			}

			if (daily) {
				return addBefore + amount + ' ' + ajax_object.text_strings.daily;
			}

			return addBefore + amount;
		}

		convertToMonthly() {
			const self = this;

			$(this.monthlySwitch).change(function () {
				// Check if the checkbox is checked
				if ($(this).prop('checked')) {
					self.amountBeforeMontlyConversion = self.getSelectedAmount();
					self.setFrequency('monthly');
					self.setPresetCustom();
					self.setAmountValue(self.getAmountAfterMonthlyConversion());
					self.toggleConversionBox();
					self.monthlyConvert(true);
				} else {
					self.setFrequency('one-time');
					self.setAmountValue(self.amountBeforeMontlyConversion);
					self.toggleConversionBox();
					self.monthlyConvert(false);
				}
			});
		}

		monthlyConvert(status) {
			$(this.convertedToMonthly).val(status);
		}

		toggleConversionBox() {
			$(this.monthlyConversionBox).toggleClass('active');
		}

		onMonthlyConversionRender() {
			const self = this;

			$(document).on('tcf-form-step-change', function (event, step) {
				var stepHTML = $(self.step).eq(step);
				if (stepHTML.hasClass('step-monthly-conversion')) {
					self.renderConversionAmountText();
					self.renderConversionAmountDescription();
					self.resetMonthlyConversion();
				}
				// reset if selected previously
			});
		}

		resetMonthlyConversion() {
			var oldAmount = this.getSelectedAmount();
			this.setFrequency('one-time');
			this.setAmountValue(oldAmount);
			$(this.monthlySwitch).prop('checked', false);
			this.monthlyConvert(false);
		}

		setFrequency(frequency) {
			var frequencyBefore = this.getSelectedFrequency();
			var targetRadio = $(this.frequencyElements).filter('[value="' + frequency + '"]');

			// Check the target radio button
			targetRadio.prop('checked', true);

			// Trigger the click event to simulate user interaction
			targetRadio.click();

			var frequencyAfter = this.getSelectedFrequency();
			this.logGroup('Frequency changed', frequencyBefore, frequencyAfter, 'setFrequency');
		}

		getAmountAfterMonthlyConversion() {
			return this.calculateMonthlyConversion(this.getSelectedOnetimeAmount());
		}

		getFormattedAmountAfterMonthlyConversion() {
			return this.formatAmount(this.calculateMonthlyConversion(this.getSelectedOnetimeAmount()));
		}

		calculateMonthlyConversion(amount) {
			const oldAmount = amount;
			if (amount >= 0 && amount <= 99) {
				amount = 12;
			} else if (amount >= 100 && amount <= 200) {
				amount = 24;
			} else {
				amount = amount;
			}
			this.logGroup('Amount converted to monthly', oldAmount, amount, 'calculateMonthlyConversion');
			return amount;
		}

		renderConversionAmountText() {
			$(this.monthlyConversionText).text(this.getFormattedAmountAfterMonthlyConversion());
		}

		renderConversionImpactString() {
			$(this.monthlyConversionText).text(this.getFormattedAmountAfterMonthlyConversion());
		}

		async renderConversionAmountDescription() {
			var monthlyConversionImpact = await this.getImpact(this.getAmountAfterMonthlyConversion(), 'monthly');

			// Use a regular expression to replace [amount] with $40
			$(this.monthlyConversionAmountDigit).text(this.getFormattedAmountAfterMonthlyConversion());

			// Replace [converted_impact] with the monthlyConversionImpact
			$(this.monthlyConversionImpactDigit).text(monthlyConversionImpact);

			if (parseInt(monthlyConversionImpact) > 1) {
				$(this.monthlyConversionImpactString).text('children');
			} else {
				$(this.monthlyConversionImpactString).text('child');
			}
		}

		// Donation Products
		productDropdown() {
			if (this.isProductsEnabled()) {
				const self = this;
				var dropdown = document.querySelector('.product-dropdown');
				var selectedProduct = dropdown.querySelector('.selected-product');
				var optionsList = dropdown.querySelector('.product-options');

				// Automatically select the first option
				var firstOption = optionsList.querySelector('li');
				selectedProduct.textContent = firstOption.textContent;
				self.showSelectedProductPresets();

				selectedProduct.addEventListener('click', function () {
					optionsList.style.display = optionsList.style.display === 'block' ? 'none' : 'block';
				});

				document.addEventListener('click', function (event) {
					// Check if the clicked element is not within the selectedProduct and optionsList
					if (!selectedProduct.contains(event.target) && !optionsList.contains(event.target)) {
						optionsList.style.display = 'none'; // Hide optionsList
					}
				});

				optionsList.addEventListener('click', function (e) {
					if (e.target.tagName === 'LI') {
						// Remove 'product="active"' attribute from all options
						optionsList.querySelectorAll('li').forEach(function (option) {
							option.removeAttribute('product');
						});

						// Add 'product="active"' attribute to the clicked option
						e.target.setAttribute('product', 'active');

						selectedProduct.textContent = e.target.textContent;
						optionsList.style.display = 'none';
						self.showSelectedProductPresets();
						$(document).trigger('tcf-form-product-change', [e.target]);
					}
				});
			}
		}

		showSelectedProductPresets() {
			if (this.isProductsEnabled()) {
				this.log('Show selected product presets');
				if (this.getActiveProductType() == 'quantity') {
					this.hideAmountPresets();
					this.showInitialQuantity();
					this.showQuantityPresets();
					this.hideImpactSection();
				} else {
					this.showAmountPresets();
					this.hideQuantityPresets();
					this.showImpactSection();
				}
			}
		}

		updateAmountinProducts() {
			$(this.productAmount).text(this.getFormattedAmount());
		}

		showInitialQuantity() {
			this.updateProductQuantity(1);
			this.setAmountValue(this.getActiveProductAmount() * 1);
			this.populateProductLabel();
		}

		updateProductQuantity(quantity) {
			$(this.productQuantity).val(quantity);
			$(this.formproductQuantity).val(this.getActiveProductQuantity());
		}

		populateProductLabel() {
			var label = this.getActiveProductLabel();
			$(this.productLabel).text(label);
		}

		updateAmountonProductQuantityChange() {
			const self = this;
			$(this.productQuantity).on('input', function () {
				var value = $(this).val();
				if (value > 0) {
					self.setAmountValue(self.getActiveProductAmount() * value);
					$(self.formproductQuantity).val(self.getActiveProductQuantity());
					self.populateProductLabel();
				}
			});
		}

		updateProductDetailsonForm() {
			if (this.isProductsEnabled()) {
				const self = this;
				$(document).on('tcf-form-product-change', function (event, product) {
					$(self.formProductId).val(self.getActiveProductId());
					$(self.formproductQuantity).val(self.getActiveProductQuantity());
				});
			}
		}

		updateProductRates(rates) {
			$.each(rates, function (key, value) {
				var product = $(`ul.product-options li[product_id='${value.id}']`);
				product.attr('product_once_amount', value.yearly);
				product.attr('product_monthly_amount', value.monthly);
			});
			return true;
		}

		limitInputNumber() {
			$(this.inputNumber).on('input', function () {
				// Ensure the value is not less than 1
				if (parseInt($(this).val()) < 1) {
					console.log('Input value cannot be less than 1');
					$(this).val(1);
				}
			});
		}

		//Helpers
		getDebugMode() {
			return this.debugMode;
		}

		log(output) {
			this.debugMode ? console.log(output) : null;
		}

		logGroup(groupName, intialValue, changedValue, functionName) {
			if (this.debugMode) {
				console.groupCollapsed(groupName);
				console.log('from', intialValue);
				console.log('to ', changedValue);
				console.log('in function ', functionName);
				console.groupEnd();
			}
		}

		setDebugMode() {
			var debug = $(this.form).attr('debug') === 'true' ? true : false;

			this.debugMode = debug;
		}

		isNotEmpty(value) {
			return value !== null && value !== undefined && value !== '';
		}

		isNotEmptyURL(urlString) {
			return !!urlString && urlString.trim().length > 0;
		}

		getAccessToken() {
			return this.debugMode ? ajax_object.staging_auth_token : ajax_object.live_auth_token;
		}

		getImpactSrc() {
			return this.debugMode ? ajax_object.staging_impact_src : ajax_object.live_impact_src;
		}

		async getImpact(amount, freq) {
			const self = this;
			const url = new URL(this.getImpactSrc());
			const params = {
				currency_code: this.getSelectedCurrency(),
				amount: amount,
				donation_type: freq,
			};

			url.search = new URLSearchParams(params).toString();
			self.formLoading(true);

			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${this.getAccessToken()}`,
					},
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				self.formLoading(false);

				return data.success.product_impact;
			} catch (error) {
				console.error(error);
				self.formLoading(false);
				throw error; // Rethrow the error for the caller to handle if needed
			}
		}

		hideImpactSection() {
			$(this.impactSection).hide();
		}

		showImpactSection() {
			$(this.impactSection).show();
		}

		getStudentSring(impact) {
			return impact > 1 ? 'students' : 'student';
		}

		getStates() {
			return this.debugMode ? ajax_object.staging_states : ajax_object.live_states;
		}

		getPmKeys() {
			return this.debugMode ? ajax_object.staging_pm_keys : ajax_object.live_pm_keys;
		}

		getHBLUrl() {
			return this.debugMode ? 'https://testsecureacceptance.cybersource.com/pay' : 'https://secureacceptance.cybersource.com/pay';
		}

		getSelectedCurrency() {
			if (this.isMulticurrencyEnabled()) {
				return $(this.getUniqueFormElements('input[name="currency"]:checked')).val();
			} else {
				return $(this.getUniqueFormElements('input[name="currency"]')).val();
			}
		}

		getLocale() {
			return document.documentElement.lang;
		}

		getSelectedDonationType() {
			return $(this.getUniqueFormElements('.active input[name="frequency"')).val();
		}

		getSelectedAmount() {
			var amt = this.convertToNum($(this.amountField).val());

			if (this.getSelectedFrequency() === 'monthly') {
				amt = this.convertToNum($(this.monthlyAmountField).val());
			}

			return !amt ? 0 : amt;
		}

		getSelectedOnetimeAmount() {
			var amt = this.convertToNum($(this.amountField).val());

			return !amt ? 0 : amt;
		}

		getCurrencySymbol() {
			return $(this.getUniqueFormElements('input[name="currency_symbol"')).val();
		}

		getFormattedAmount() {
			// Get the input value
			let formattedValue = this.getLocaleFormattedAmount(this.getSelectedAmount());

			return this.getCurrencySymbol() + (!formattedValue ? 0 : formattedValue);
		}

		formatAmount(amount) {
			return this.getCurrencySymbol() + this.getLocaleFormattedAmount(amount);
		}

		getLocaleFormattedAmount(amount) {
			let locale = ajax_object.locale;

			// Format the number using number_format_i18n equivalent logic
			return new Intl.NumberFormat(locale).format(amount);
		}

		getSelectedFund() {
			return $(this.selectedFund).val();
		}

		getGiftAidBillingMethod() {
			return $(this.giftAidBillingMethodSelected).val();
		}

		getSelectedAmountLevel() {
			return $.trim($(this.presetsAmountContainer).find('.active label').html());
		}

		getSelectedCity() {
			return $(this.cityField).val();
		}

		getGiftAidValue() {
			return $(this.selectedGiftAidOption).val() == '1' ? true : false;
		}

		isGiftAidEnabled() {
			return $(this.form).attr('is_giftaid_enabled') == 'true' ? true : false;
		}

		isMulticurrencyEnabled() {
			return $(this.form).attr('is_multicurrency') == 'true' ? true : false;
		}

		isProductsEnabled() {
			return JSON.parse(ajax_object.products_enable);
		}

		isMonthlyOnly() {
			return $(this.form).attr('is_monthly_only') == 'true' ? true : false;
		}

		isDailyOnly() {
			return $(this.form).attr('is_daily_only') == 'true' ? true : false;
		}

		isOnceOnly() {
			return $(this.form).attr('is_once_only') == 'true' ? true : false;
		}

		getSelectedCountry() {
			return $(this.selectedCountry).val();
		}

		getSelectedCountryCode() {
			return $(this.selectedCountry).attr('country_code');
		}

		getChapterId() {
			return $(this.chapterIdField).val();
		}

		getCustomThankyouURL() {

			let url = undefined;

			if ( $(this.customThankyouPage).val() ) {
				url = $(this.customThankyouPage).val();

				if(url.substr(-1) == '/') {
				    url = url.substr(0, url.length - 1);
				}
			}

			return url;
		}

		getFormName() {
			return $(this.formName).val();
		}

		getChapterCountryCode() {
			return $(this.chapterCountryCode).val();
		}

		getDoubleImpact() {
			return $(this.corporateMatchingCheckbox).is(':checked');
		}

		getDoubleImpactEmployer() {
			return $(this.corporateMatchingTextBox).val();
		}

		getPortfolioIsChecked() {
			return $(this.portfolioCheckbox).is(':checked');
		}

		getSelectedPortfolio() {
			return $(this.portfolioSelect).find(':selected').html();
		}

		getSelectedProject() {
			return $(this.projectField).val();
		}

		getTransationFeePercentage() {
			return parseFloat($(this.getUniqueFormElements('input[name="payment_method"]:checked')).attr('fee'));
		}

		getDonationId() {
			return this.donation_id !== undefined ? this.donation_id : null;
		}

		getSelectedPaymentMethod() {
			return $(this.getUniqueFormElements('input[name="payment_method"]:checked')).val();
		}

		clearSelectedPaymentMethod() {
			return $(this.getUniqueFormElements('input[name="payment_method"]')).prop('checked', false);
		}

		getCurrentTransactionFee() {
			return this.convertToNum($(this.transactionFeeAmount).val());
		}

		resetTransactionFee() {
			$(this.transactionFeeAmount).val('');
		}

		getSelectedFrequency() {
			return $(this.selectedFrequency).val();
		}

		getNumberOfSteps() {
			return $(this.step).length;
		}

		getFormData() {
			let self = this;

			var formData = new FormData(document.querySelector(self.form));

			var jsonData = JSON.stringify(Object.fromEntries(formData.entries()));

			// Add elapsedTime to jsonData
			var dataObj = self.filterFormObj(JSON.parse(jsonData));
			dataObj.total_time_taken = self.elapsedTime;
			dataObj.debug = self.debugMode;

			if (dataObj.amount === '') dataObj.amount = self.getSelectedAmount();

			if (!dataObj.payment_method) dataObj.payment_method = self.getSelectedPaymentMethod();

			// Add source information to jsonData
			dataObj.source_date = self.collectSourceDate();
			dataObj.source_url = self.collectSourceURL();

			// Add utm information to jsonData
			dataObj.utm_date = self.collectUTMDate();
			dataObj.utm_source = self.collectUTMSource();
			dataObj.utm_medium = self.collectUTMMedium();
			dataObj.utm_campaign = self.collectUTMCampaign();
			dataObj.utm_term = self.collectUTMTerm();
			dataObj.utm_content = self.collectUTMContent();

			return dataObj;
		}

		getRecaptchaToken(action = '') {
			const token = grecaptcha.execute(ajax_object.recaptcha_sitekey, {
				action: action,
			});

			return token;
		}

		convertToNum(value) {
			return Number(parseFloat(value).toFixed(2));
		}

		isValidAmount() {
			const selectedAmount = this.getSelectedAmount();
			const isValidAmount = !isNaN(parseFloat(selectedAmount)) && parseFloat(selectedAmount) !== 0;
			return isValidAmount;
		}

		isValidFee() {
			const fee = this.getTransationFeePercentage();
			const isValidFee = !isNaN(parseFloat(fee)) && parseFloat(fee) !== 0;
			return isValidFee;
		}

		isPaymentMethodSelected() {
			return $(this.getUniqueFormElements('input[name="payment_method"]:checked')).length > 0;
		}

		getDonorEmail() {
			return $(this.getUniqueFormElements('input[name="email"]')).val();
		}

		getDonorFirstname() {
			let firstName = $(this.getUniqueFormElements('input[name="firstname"]')).val();
			return firstName;
		}

		getDonorSurname() {
			let lastName = $(this.getUniqueFormElements('input[name="lastname"]')).val();
			return lastName;
		}

		getDonorPhone() {
			let phone = $(this.getUniqueFormElements('input[name="phone"]')).val();
			return phone;
		}

		getDonorFullName() {
			let firstName = $(this.getUniqueFormElements('input[name="firstname"]')).val();
			let lastName = $(this.getUniqueFormElements('input[name="lastname"]')).val();
			return firstName + ' ' + lastName;
		}

		getCurrencyId() {
			return parseInt($(this.getUniqueFormElements('input[name="currency_id"]')).val());
		}

		getTrackingInfo() {
			const sourceData = this.collectSourceData();
			const utmData = this.collectUTMData();

			return {
				source: sourceData,
				utm_data: utmData,
			};
		}

		getExpiresInArray(collectionDate) {
			// Parse sourceDate and ensure it's in UTC
			const parsedSourceDate = new Date(Date.parse(collectionDate));
			if (isNaN(parsedSourceDate.getTime())) {
				// Handle invalid date format
				return {
					error: 'Invalid date format for sourceDate',
				};
			}
			// Calculate expiry time (24 hours later)
			const expiryTime = new Date(parsedSourceDate.getTime() + 7 * 24 * 60 * 60 * 1000);

			// Convert currentDate to UTC
			const currentDate = new Date();
			const currentDateUTC = new Date(currentDate.toUTCString());

			const timeDifference = Math.abs(expiryTime - currentDateUTC);

			const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
			const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

			const expires_in = {
				days: daysDifference,
				hours: hoursDifference,
				minutes: minutesDifference,
				words: `${daysDifference} days, ${hoursDifference} hours, ${minutesDifference} minutes`,
			};

			return expires_in;
		}

		toggleAmountField(show) {
			if (show === true) {
				$(this.amountDiv).show();

				$(this.amountInputField).attr('type', 'number').focus();
			} else {
				$(this.amountDiv).hide();

				$(this.amountInputField).attr('type', 'hidden');
			}
		}

		hideAmountPresets() {
			$(this.presetsAmountContainer).hide();
		}

		showAmountPresets() {
			$(this.presetsAmountContainer).show();
		}

		showQuantityPresets() {
			$(this.presetsQuantityContainer).show();
		}

		hideQuantityPresets() {
			$(this.presetsQuantityContainer).hide();
		}

		urlEncodeBase64(input) {
			// Base64 Encoding
			let base64 = btoa(input);
			return base64;
		}

		displayError(message) {
			$(this.errorContainer).text(message);
		}

		clearErrorContainer() {
			$(this.errorContainer).empty();
		}

		getActiveProductType() {
			return $(this.productActive).attr('type');
		}

		getActiveProductLabel() {
			if (this.getActiveProductQuantity() > 1) {
				return this.getActiveProductPluralLabel();
			} else {
				return this.getActiveProductSingularLabel();
			}
		}

		getActiveProductSingularLabel() {
			return $(this.productActive).attr('product_name_singular');
		}

		getActiveProductPluralLabel() {
			return $(this.productActive).attr('product_name_plural');
		}

		getActiveProductAmount() {
			if (this.getSelectedFrequency() === 'monthly') {
				return this.getActiveProductMonthlyAmount();
			} else {
				return this.getActiveProductOnetimeAmount();
			}
		}

		getActiveProductOnetimeAmount() {
			return $(this.productActive).attr('product_once_amount');
		}

		getActiveProductMonthlyAmount() {
			return $(this.productActive).attr('product_monthly_amount');
		}

		getActiveProductId() {
			return $(this.productActive).attr('product_id');
		}

		getActiveProductQuantity() {
			const quantity = parseInt($(this.productQuantity).val());
			return isNaN(quantity) ? 0 : quantity;
		}

		isIntEqual(firstValue, secondValue) {
			return parseInt(firstValue) === parseInt(secondValue);
		}

		isStringEqual(firstValue, secondValue) {
			return String(firstValue) === String(secondValue);
		}

		getMonthlyConvertStatus() {
			return JSON.parse($(this.convertedToMonthly).val());
		}

		// translations

		translatePaymentMethodNames() {
			//payment-method-name

			let self = this;

			if (self.getLocale() == 'it') {
				$('.payment-method-name').each(function (index) {
					let text = $(this).text();

					if (text == 'Debit/Credit Card') {
						$(this).text('CARTA DI CREDITO/DEBITO');
					}
				});
			}
		}

		// External Functions

		_setAmount(amount) {
			this.scrolltoForm();
			this.goToStep(0);
			this.renderImpact();
			this.toggleAmountField(true);
			this.setPresetCustom();
			this.setAmountValue(amount);
		}
	}

	function getUTMParams() {
		const urlParams = new URLSearchParams(window.location.search);

		return {
			utm_source: urlParams.get('utm_source') || '',
			utm_medium: urlParams.get('utm_medium') || '',
			utm_campaign: urlParams.get('utm_campaign') || '',
			utm_content: urlParams.get('utm_content') || '',
			utm_term: urlParams.get('utm_term') || '',
		};
	}

	function storeTrackingInfo() {
		const referrer = document.referrer;
		const currentDomain = window.location.hostname;
		const currentDomains = [window.location.hostname, 'secureacceptance.cybersource.com'];
		const expiryTime = new Date();
		expiryTime.setTime(expiryTime.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

		// Check if the referrer is not from the current domain
		if (referrer !== '' && !currentDomains.some((domain) => referrer.includes(domain))) {
			if (!getCookie('donation_source')) {
				const cookieValue = {
					referrer: referrer,
					dateRecorded: new Date().toUTCString(),
				};
				console.log('Setting referrer cookie', referrer);

				const encodedCookieValue = encodeURIComponent(JSON.stringify(cookieValue));

				document.cookie = `donation_source=${encodedCookieValue}; expires=${expiryTime.toUTCString()}; path=/;`;
			}
		}

		// Check if the utm data is present
		if (window.location.search.includes('utm_')) {
			if (!getCookie('donation_utm')) {
				const utmParams = getUTMParams();

				const cookieValue = {
					dateRecorded: new Date().toUTCString(),
					utm: utmParams,
				};

				const encodedCookieValue = encodeURIComponent(JSON.stringify(cookieValue));

				document.cookie = `donation_utm=${encodedCookieValue}; expires=${expiryTime.toUTCString()}; path=/;`;
			}
		}
	}

	function getCookie(name) {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.startsWith(name + '=')) {
				return cookie.substring(name.length + 1);
			}
		}
		return null;
	}

	$(document).ready(function () {
		// get all elements with class name "global-donation-form"
		const forms = document.querySelectorAll('.global-form-wrapper');

		// fetch and store source information on intial page load.
		storeTrackingInfo();

		// loop through each form and add a unique ID
		forms.forEach((form, index) => {
			const formId = `global-donation-form-${index}`;
			form.setAttribute('id', formId);
			// execute the class with the unique ID
			// replace "ClassToExecute" with the actual class name you want to execute
			window.formClass = new FormClass(formId);
		});
	});
})(jQuery);
