(function ($) {
	$(document).ready(function () {
		tcfPixelEvents.init();
		tcfGAEvents.init();
		tcfGAdsEvents.init();
	});

	window.tcfPixelEvents = {
		init: function () {
			console.log('Pixel Events init');

			$(document).on('tcf-form-step-change', function (e, currentStep) {
				if (currentStep == 1) {
					tcfPixelEvents.passedDonationFirstStep();
				}

				if (currentStep == 2) {
					tcfPixelEvents.passedDonationSecondStep();
				}
			});

			$(document).on('tcf-form-payment-initiated', function (e, currentStep, nextStep) {
				tcfPixelEvents.passedDonationThirdStep();
			});

			$(document).on('tcf-form-thankyou-receipt-loaded', function (e, currentStep, nextStep) {
				tcfPixelEvents.donationSuccess();
			});
		},

		passedDonationFirstStep: function () {
			console.log('passedDonationFirstStep');
			var formAttrs = tcfPixelEvents.getFormAttributes();
			tcfPixelEvents.passEventToPixel('payment_initiated', formAttrs);
		},

		passedDonationSecondStep: function () {
			var formAttrs = tcfPixelEvents.getFormAttributes();
			tcfPixelEvents.passEventToPixel('information_submitted', formAttrs);
		},

		passedDonationThirdStep: function () {
			var formAttrs = tcfPixelEvents.getFormAttributes();
			tcfPixelEvents.passEventToPixel('donate_button_clicked', formAttrs);
		},

		donationSuccess: function () {
			let data = {
				donation_for: window.donation_obj.donation_for,
				donation_type: window.donation_obj.donation_type,
				donation_amount: window.donation_obj.donation_amount,
				frequency: window.donation_obj.frequency,
				currency: window.donation_obj.currency_code,
				value: window.donation_obj.donation_amount,
				donation_id: window.donation_obj.donation_id,
			};

			tcfPixelEvents.passEventToPixel('payment_confirmed', data);
			tcfPixelEvents.passEventToPixel('Donate', data);
			tcfPixelEvents.passEventToPixel(
				'Purchase',
				{ currency: data.currency, value: data.value, content_name: data.donation_for, donation_id: data.donation_id },
				{ eventID: 'purchase.' + data.donation_id },
			);
		},

		passEventToPixel: function (event, attrs = {}) {
			if (typeof fbq == 'function') {
				console.log('fired pixel event: ', event);
				console.log('event attrs: ', attrs);

				if (event == 'Donate' || event == 'Purchase') {
					fbq('track', event, attrs);
				} else {
					fbq('trackCustom', event, attrs);
				}
			} else {
				console.log('could not fire ga event, dataLayer not found: ', event);
			}
		},

		getFormAttributes: function () {
			let data = {
				donation_for: window.formClass.getFormName(),
				donation_type: window.formClass.getSelectedFund(),
				amount_option: window.formClass.getSelectedAmountLevel(),
				donation_amount: window.formClass.getSelectedAmount(),
				frequency: window.formClass.getSelectedDonationType(),
				currency: window.formClass.getSelectedCurrency(),
				value: window.formClass.getSelectedAmount().toFixed(2),
			};

			var city = window.formClass.getSelectedCity();
			var country = window.formClass.getSelectedCountry();
			var doubleImpact = window.formClass.getDoubleImpact();
			var isPortfolioCheckSelected = window.formClass.getPortfolioIsChecked();
			var portfolio = window.formClass.getSelectedPortfolio();
			var project = window.formClass.getSelectedProject();

			if (city.length > 0) data.city = city;

			if (country) data.country = country;

			if (doubleImpact) data.double_your_impact = doubleImpact;

			if (isPortfolioCheckSelected) {
				data.specific_chapter = portfolio;
				data.chapter_name = project;
			}

			return data;
		},
	};

	window.tcfGAEvents = {
		init: function () {
			tcfGAEvents.clickedExploreSchoolsBtn();
			tcfGAEvents.clickedEducationProgrameBtn();
			tcfGAEvents.clickedDonateBtn();
			tcfGAEvents.clickedOtherWaysToDonateBtn();
			tcfGAEvents.clickedSocialsBtn();
			tcfGAEvents.clickedContactFormSubmit();
			tcfGAEvents.clickedNewsletterSubscribeBtn();

			$(document).on('tcf-form-step-change', function (e, currentStep) {
				if (currentStep == 1) {
					tcfGAEvents.passedDonationFirstStep();
				}

				if (currentStep == 2) {
					tcfGAEvents.passedDonationSecondStep();
				}
			});

			$(document).on('tcf-form-payment-initiated', function (e, currentStep, nextStep) {
				tcfGAEvents.passedDonationThirdStep();
			});

			$(document).on('tcf-form-thankyou-receipt-loaded', function (e, currentStep, nextStep) {
				tcfGAEvents.donationSuccess();
			});
		},

		clickedExploreSchoolsBtn: function () {
			$('a[href*="/our-schools"]').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('explore_school_clicked');
			});
		},

		clickedEducationProgrameBtn: function () {
			$('a[href*="/education-programme"]').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('learn_more_clicked');
			});
		},

		clickedDonateBtn: function () {
			$('a[href$="/donate/"]').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('donate_now_clicked');
			});
		},

		clickedOtherWaysToDonateBtn: function () {
			$('a[href$="/donation-information/"]').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('other_ways_to_donate_clicked');
			});
		},

		clickedSocialsBtn: function () {
			$('li.wp-social-link a').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('social_button_clicked');
			});
		},

		clickedContactFormSubmit: function () {
			$('form[action*="contact-us"] .wpcf7-submit').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('query_message_sent');
			});
		},

		clickedNewsletterSubscribeBtn: function () {
			$('.hustle-button-submit').on('click touchend', function (e) {
				tcfGAEvents.passEventToGA('newsletter_subscribed');
			});
		},

		passedDonationFirstStep: function () {
			var formAttrs = tcfGAEvents.getFormAttributes();
			tcfGAEvents.passEventToGA('payment_initiated', formAttrs);
		},

		passedDonationSecondStep: function () {
			var formAttrs = tcfGAEvents.getFormAttributes();
			tcfGAEvents.passEventToGA('information_submitted', formAttrs);
		},

		passedDonationThirdStep: function () {
			var formAttrs = tcfGAEvents.getFormAttributes();
			tcfGAEvents.passEventToGA('donate_button_clicked', formAttrs);
		},

		donationSuccess: function () {
			//var formAttrs = tcfGAEvents.getFormAttributes()

			let data = {
				donation_for: window.donation_obj.donation_for,
				donation_type: window.donation_obj.donation_type,
				donation_amount: window.donation_obj.donation_amount,
				frequency: window.donation_obj.frequency,
				currency: window.donation_obj.currency_code,
				value: window.donation_obj.donation_amount,
				donation_id: window.donation_obj.donation_id,
			};

			tcfGAEvents.passEventToGA('payment_confirmed', data);
		},

		passEventToGA: function (event, attrs = {}) {
			if (dataLayer.length > 0) {
				console.log('fired ga event: ', event);
				console.log('event attrs: ', attrs);
				attrs.event = event;
				dataLayer.push(attrs);
			} else {
				console.log('could not fire ga event, dataLayer not found: ', event);
			}
		},

		getFormAttributes: function () {
			let data = {
				donation_for: window.formClass.getFormName(),
				donation_type: window.formClass.getSelectedFund(),
				amount_option: window.formClass.getSelectedAmountLevel(),
				donation_amount: window.formClass.getSelectedAmount(),
				frequency: window.formClass.getSelectedDonationType(),
				currency: window.formClass.getSelectedCurrency(),
			};

			var city = window.formClass.getSelectedCity();
			var country = window.formClass.getSelectedCountry();
			var doubleImpact = window.formClass.getDoubleImpact();
			var isPortfolioCheckSelected = window.formClass.getPortfolioIsChecked();
			var portfolio = window.formClass.getSelectedPortfolio();
			var project = window.formClass.getSelectedProject();

			if (city.length > 0) data.city = city;

			if (country) data.country = country;

			if (doubleImpact) data.double_your_impact = doubleImpact;

			if (isPortfolioCheckSelected) {
				data.specific_chapter = portfolio;
				data.chapter_name = project;
			}

			if (window.donation_id != null) data.donation_id = window.donation_id;

			return data;
		},
	};

	window.tcfGAdsEvents = {
		init: function () {
			$(document).on('tcf-form-thankyou-receipt-loaded', function (e, currentStep, nextStep) {
				tcfGAdsEvents.donationSuccess();
			});
		},

		donationSuccess: function () {
			let formAttrs = {
				send_to: ajax_object.google_ads_conversion_id,
				value: window.donation_obj.donation_amount,
				currency: window.donation_obj.currency_code,
				transaction_id: window.donation_obj.donation_id,
			};

			tcfGAdsEvents.passEventToGAds('conversion', formAttrs);
		},

		passEventToGAds: function (event, attrs = {}) {
			if (typeof gtag !== 'undefined') {
				console.log('Sent conversion event to google ads manager');
				console.log('event attrs: ', attrs);
				gtag('event', event, attrs);
			}
		},
	};
})(jQuery);
